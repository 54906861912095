import { get } from '../axios';

export default {
  /**
   * Get responsible persons for a document
   *
   * @param {String} clientElementId
   * @returns
   */
  getBbhResponsibility(clientElementId) {
    const URL = `/client/clientElement/${clientElementId}/bbhResponsibility`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => {
          if (error.response.status === 404) {
            resolve(null);
            return;
          }

          reject(error);
        });
    });
  }
};
