// rfc6902 version 3.0.4 ist 100x schneller als 4.0.3
import { applyPatch, createPatch } from 'rfc6902';
import { isJSON } from '@st/utils-js';

export default class Patcher {
  /**
   *
   * @param {JSON} oldObj
   * @param {JSON} newObj
   * @returns patches to transform the old JSON to the new one
   */
  static createPatches(oldObj, newObj) {
    return createPatch(oldObj, newObj);
  }

  /**
   *
   * @param {Object} obj1
   * @param {Object} obj2
   * @returns true if the objects are equal;
   */
  static compare(obj1, obj2) {
    function isObject(value) {
      return value !== undefined && typeof value === 'object';
    }
    if (isObject(obj1) && isObject(obj2)) {
      return Patcher.createPatches(obj1, obj2).length === 0;
    }
    return false;
  }

  /**
   *
   * @param {JSON} oldObj
   * @param {Array} patches
   * @returns [null] if successfully updated
   */
  static applyPatches(oldObj, patches) {
    if (!isJSON(oldObj) || !Array.isArray(patches)) return oldObj;
    if (patches.length === 0) return [ null ];

    return applyPatch(oldObj, patches);
  }
}
