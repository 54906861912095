import config from './config';
import {
  get, post, put
} from '../axios';
import { uuid } from '../../utils/uuid';
import ElementType from '../../model/enums/ElementTyp';

const { API_PREFIX } = config;

export default {

  /**
   * Fetches all hashtags.
   * @returns Array of hashtags
   */
  fetchAll() {
    const URL = `${API_PREFIX}/hashtag/NOT_DEPRECATED`;
    return new Promise((resolve, reject) => {
      get(URL)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Fetches all hashtags of elementType.
   * @returns Array of hashtags
   */
  fetchAllByType(type = ElementType.MUSTERDOKUMENT) {
    const URL = `${API_PREFIX}/hashtag/${type}`;
    return new Promise((resolve, reject) => {
      get(URL)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Fetches all types of an element type.
   * @param {String} elementType type of the class ElementType
   * @param {String} elementSubType type of the class ElementSubType
   * @returns Array of hashtag types
   */
  fetchTypes(elementType, elementSubType = 'STANDARD') {
    const URL = `${API_PREFIX}/hashtagTypes/${elementType}/${elementSubType}`;
    return new Promise((resolve, reject) => {
      get(URL)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * Fetches all hashtags of a hashtag type.
   * @param {String} hashtagTypeId
   * @returns Array of hashtags
   */
  fetchByType(hashtagTypeId) {
    const URL = `${API_PREFIX}/hashtags/${hashtagTypeId}`;
    return new Promise((resolve, reject) => {
      get(URL)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} name hashtagname
   * @param {String} id UUID of the hashtag. If null than an UUID will be generated.
   * @param {String} hashtagTypeId UUID of the hashtag type
   * @returns
   */
  save({ name, id = null, type }) {
    const URL = `${API_PREFIX}/hashtag`;
    const PAYLOAD = {
      id: id || uuid(),
      name,
      hashtagTypeId: type.id
    };
    return new Promise((resolve, reject) => {
      put(URL, PAYLOAD)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /**
   * checks hashtag: is hashtag existing in the db or not
   * @param {String} name
   * @returns Boolean
   */
  check(name) {
    const URL = `${API_PREFIX}/hashtagexists`;
    const PAYLOAD = {
      hashtag: name
    };

    return new Promise((resolve, reject) => {
      post(URL, PAYLOAD)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }
};
