export function getRouteVariableIndizes(route) {
  return route
    .split('/')
    .reduce((acc, v, i) => {
      if (v.includes(':')) acc.push(i);
      return acc;
    }, []);
}

export function removeUrlVariables(url, variableIndizes) {
  return url
    .split('/')
    .reduce((acc, str, i) => {
      let value = str;
      if (variableIndizes.includes(i)) value = '';
      acc.push(value);
      return acc;
    }, [])
    .join('/');
}

export default function transformToHilfeVideoPath(url, route) {
  const indizes = getRouteVariableIndizes(route);

  return removeUrlVariables(url, indizes)
    .replaceAll('/', '_');
}
