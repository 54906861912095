import { isJSON } from '@st/utils-js';
import BausteinElement from '../model/BausteinElement';
import ElementTyp from '../model/enums/ElementTyp';
import KlauselElement from '../model/KlauselElement';
import MusterdokumentElement from '../model/MusterdokumentElement';
import MustervertragElement from '../model/MustervertragElement';
import { validate } from './uuid';

export default class ElementFactory {
  /**
   * Creates Element Object from JSON.
   * If keys are missing then it will use the default values.
   * @param {String} id
   * @param {JSON} elements
   * @param {Number} revision
   * @param {JSON} similarities
   * @returns null if invalid or
   *  KlauselElement, MustervertragElement, MusterdokumentElement or BausteinElement
   */
  static elementFromJSON(id, elements, revision = 0, similarities = {}) {
    const value = elements[id];

    if (!isJSON(value) || !validate(id) || !ElementTyp.isValid(value.type)) return null;

    const { type } = value;
    const preparedValue = { ...value, id, revision };

    if (ElementTyp.isMustervertrag(type)) {
      return MustervertragElement.fromJSON(preparedValue, elements);
    } if (ElementTyp.isMusterdokument(type)) {
      return MusterdokumentElement.fromJSON(preparedValue, elements, similarities);
    } if (ElementTyp.isKlausel(type)) {
      return KlauselElement.fromJSON(preparedValue, elements, similarities);
    } if (ElementTyp.isBaustein(type)) {
      return BausteinElement.fromJSON(preparedValue);
    }

    return null;
  }
}
