export default class AntwortTyp {
  static ANSWER = 'answer';

  static VALUE = 'VALUE';

  static isValidState(state) {
    const states = [
      this.VALUE, this.ANSWER
    ];
    return states.includes(state);
  }

  static isStateAnswer(state) {
    return state === this.ANSWER;
  }

  static isStateValue(state) {
    return state === this.VALUE;
  }
}
