import { component as MODULE } from '../store/state';

const GETTERS = {
  CHOICE_TRIGGER: `${MODULE}choice_trigger`,

  CURRENT_DOC_ID: `${MODULE}current_doc_id`,
  CURRENT_MASTER_CONTRACT: `${MODULE}current_master_contract`,

  ELEMENT: `${MODULE}element`,
  BUILD_MODEL: `${MODULE}build_model`,
  MASTER_ELEMENT: `${MODULE}master_element`,

  NUMBERING: `${MODULE}numbering`
};

export default GETTERS;
