export default class CompiledContentElement {
  constructor(
    refElement = {},
    refPathId = undefined,
    ancestors = [],
    similarities = [],
    elementRevision = undefined
  ) {
    this.refElement = refElement;
    this.refPathId = refPathId;
    this.ancestors = ancestors;
    this.similarities = similarities;
    this.elementRevision = elementRevision;
  }

  static fromJSON(json) {
    if (json instanceof CompiledContentElement) return json;

    return new CompiledContentElement(
      json.refElement,
      json.refPathId,
      json.ancestors,
      json.similarities,
      json.elementRevision
    );
  }
}
