import { component as MODULE } from '../store/state';

const GETTERS = {
  ALL_HASHTAGS: `${MODULE}all_hashtags`,
  FILTERED_HASHTAGS: `${MODULE}filtered_hashtags`,
  ALL_HASHTAG_TYPES: `${MODULE}all_hashtag_types`,
  ALL_CHOICES: `${MODULE}all_choices`,
  ALL_MUSTERVERTRAEGE: `${MODULE}all_mustervertraege`,

  HILFE_VIDEO: `${MODULE}hilfe_video`
};

export default GETTERS;
