import MandantDokumentElement from '@src/model/MandantDokumentElement';
import { deepClone, isEmptyOrNullish } from '@st/utils-js';
import Patcher from './Patcher';

/**
 *
 * @param {MandantDokumentElement} storeClientElement
 * @param {{[sectionId]: AenderungsmodusSection}} sections
 * @param {String} subElementId
 * @returns
 */
function applyPatchesOnClientElement(storeClientElement, sections, subElementId) {
  if (!(storeClientElement instanceof MandantDokumentElement)) throw new Error('Invalid storeClientElement');

  const tmpStoreClientElement = deepClone(storeClientElement.toJSON());

  const oldClientElement = tmpStoreClientElement.clientElement;

  const clientElementSections = oldClientElement.subElements[subElementId].sections.contents;

  const sectionIds = oldClientElement.subElements[subElementId].sections.order;

  sectionIds.forEach((sectionId) => {
    if (!sections[sectionId]) return;
    const section = sections[sectionId];
    const sectionPatches = section.patches;
    if (isEmptyOrNullish(clientElementSections[sectionId])) {
      clientElementSections[sectionId] = {};
    }
    const oldSection = clientElementSections[sectionId];

    sectionPatches.forEach((patchObj) => {
      Patcher.applyPatches(
        oldSection,
        patchObj.patch
      );
    });
  });

  return MandantDokumentElement.fromJSON(tmpStoreClientElement);
}

function applyPatchesForSectionOnClientElement(
  storeClientElement,
  baseOrHead,
  sections,
  sectionId,
  subElementId
) {
  if (!(storeClientElement instanceof MandantDokumentElement)) throw new Error('Invalid storeClientElement');

  const copyOriginClientElement = MandantDokumentElement.fromJSON(
    deepClone(storeClientElement.toJSON())
  );
  let updatedClientElement = copyOriginClientElement;

  const copyAenderungsSections = deepClone(sections);

  if (copyAenderungsSections[sectionId]) {
    const requiredSection = copyAenderungsSections[sectionId];
    requiredSection.patches = requiredSection.patches.filter(
      (patch) => patch.revision <= baseOrHead.revision
    );

    const sectionsProp = {
      [sectionId]: requiredSection
    };

    updatedClientElement = applyPatchesOnClientElement(
      copyOriginClientElement,
      sectionsProp,
      subElementId
    );
  }

  return updatedClientElement
    .clientElement.subElements[subElementId].content
    .contents[sectionId];
}

export {
  applyPatchesForSectionOnClientElement,
  applyPatchesOnClientElement
};
