import { authWrapper } from '@src/services/axios';
import types from '../../../types';
import services from '../../../../services';
import KeycloakUser from '../../../../model/KeycloakUser';
import Tag from '../../../../model/Tag';

const viewContentMutations = types.mutations.viewContent;

const mandantEditorMutations = types.mutations.mandantEditor;

const userMutations = types.mutations.user;
const userActions = types.actions.user;
const userGetters = types.getters.user;

export default {
  [userActions.FETCH_USER_CLIENTS]({ commit }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.fetchUserClients()
        .then((result) => {
          commit(userMutations.SET_USER_CLIENTS, result.data.clients);
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.PUT_CLIENT]({ commit }, { clientObj }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClient(clientObj)
        .then((result) => {
          commit(userMutations.UPDATE_CLIENT, { clientObj });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.PUT_USER_DETAILS]({ commit, getters }, { user }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.putUserDetails(user)
        .then((result) => {
          const currentUser = getters[userGetters.USER];
          const combinedUser = { ...currentUser, ...user };
          const mappedUser = KeycloakUser.fromJSON(combinedUser);
          commit(userMutations.SET_USER, mappedUser);
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response?.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.GET_ALL_USERS_OF_CLIENT]({ commit }, { clientId }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getUsers(clientId)
        .then((result) => {
          const clientUsers = result.data.users.map(
            (user) => KeycloakUser.fromJSON(user)
          );
          commit(userMutations.SET_CLIENT_USERS, { clientUsers });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.PUT_CLIENT_ELEMENT_RESPONSIBILITY]({ commit }, {
    clientElementId,
    responsibility,
    keycloakUser
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.putClientElementResponsibility(
        clientElementId,
        responsibility,
        keycloakUser.id
      )
        .then((result) => {
          commit(mandantEditorMutations.PUT_CLIENT_ELEMENT_RESPONSIBILITY, {
            clientElementId,
            keycloakUser
          });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.DELETE_CLIENT_ELEMENT_RESPONSIBILITY]({ commit }, {
    clientElementId,
    keycloakUserUuid
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.deleteClientElementResponsibility(clientElementId, keycloakUserUuid)
        .then((result) => {
          commit(mandantEditorMutations.DELETE_CLIENT_ELEMENT_RESPONSIBILITY, {
            clientElementId,
            keycloakUserUuid
          });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.GET_USER_TAGS]({ commit }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.getUserTags()
        .then((result) => {
          const tags = result.data.tags.map((tag) => Tag.fromJSON(tag));
          commit(userMutations.SET_USER_TAGS, {
            tags
          });

          resolve(tags);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response?.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.PUT_USER_TAG]({ commit }, {
    tag
  }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.putUserTag({ tag })
        .then((result) => {
          commit(userMutations.ADD_OR_UPDATE_USER_TAG, {
            tag
          });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.DELETE_USER_TAG]({ commit }, {
    tagId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.deleteUserTag(tagId)
        .then((result) => {
          commit(userMutations.DELETE_USER_TAG, {
            tagId
          });
          commit(
            mandantEditorMutations.DELETE_TAG_FROM_ALL_CLIENT_ELEMENTS,
            { tagId }
          );
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.POST_REQUEST_ROLE]({ state, commit }, {
    userRoleObj,
    newRoles
  }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.postRequestRole(userRoleObj)
        .then((result) => {
          const clientId = userRoleObj.clientIdAdditionalRole;
          if (
            userRoleObj.keycloakUserUuid === state.user.id
            && clientId === state.user.clients[0].clientId
          ) {
            userRoleObj.requestedAdditionalRoles.forEach((role) => {
              const mappedRole = {
                name: role,
                clientId
              };
              commit(userMutations.ADD_USER_ROLE, { role: mappedRole });
            });
            userRoleObj.requestedKeycloakRoles.forEach((role) => {
              const mappedRole = newRoles.find(
                (newRole) => newRole.keycloakClientRoleUuid === role
              );
              commit(userMutations.ADD_USER_ROLE, { role: mappedRole });
            });
          }
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.DELETE_KEYCLOAK_ROLE]({ commit }, {
    keycloakRoleUuid,
    keycloakUserUuid
  }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.deleteKeycloakRole(keycloakUserUuid, keycloakRoleUuid)
        .then((result) => {
          commit(userMutations.REMOVE_USER_ROLE, { keycloakUserUuid, keycloakRoleUuid });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.DELETE_ADDITIONAL_ROLE]({ commit }, {
    clientId,
    additionalRole,
    keycloakUserUuid
  }) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.deleteAdditionalRole(keycloakUserUuid, clientId, additionalRole)
        .then((result) => {
          commit(userMutations.REMOVE_USER_ROLE, { keycloakUserUuid, additionalRole });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },

  [userActions.GET_PATH_PREFERENCE]({ commit }, path) {
    const prm = new Promise((resolve, reject) => {
      services.user.users.getPathPreference(path)
        .then((result) => {
          const value = result?.data.value ? result.data.value : false;
          commit(userMutations.SET_USER_PREFERENCE, { key: path, value });
          resolve(result);
        })
        .catch((error) => {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            error.message || error.response.status
          );

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  }
};
