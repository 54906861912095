export default class HashtagStatus {
  static NEW = 'new';

  static APPROVED = 'approved';

  static DEPRECATED = 'deprecated';

  static isValidState(state) {
    const states = [
      this.NEW, this.APPROVED, this.DEPRECATED
    ];

    return states.includes(state);
  }

  static isNew(state) {
    return state === this.NEW;
  }

  static isApproved(state) {
    return state === this.APPROVED;
  }

  static isDeprecated(state) {
    return state === this.DEPRECATED;
  }
}
