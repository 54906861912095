import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import UserRolle from './enums/UserRolle';

export default class Responsibilities {
  static ROLES = UserRolle.ROLES;

  /**
   *
   * @param {String} rechtsanwaltId UUID
   * @param {String} partnerId UUID
   * @param {String} admintPartnerId UUID
   * @param {String} produktkoordinatorId UUID
   * @param {String} mandantId UUID
   * @param {String} sachbearbeiterId UUID
   */
  constructor(
    rechtsanwaltId = null,
    partnerId = null,
    admintPartnerId = null,
    produktkoordinatorId = null,
    mandantId = null,
    sachbearbeiterId = null
  ) {
    if (rechtsanwaltId) this[UserRolle.RECHTSANWALT] = rechtsanwaltId;
    if (partnerId) this[UserRolle.PARTNER] = partnerId;
    if (admintPartnerId) this[UserRolle.ADMINPARTNER] = admintPartnerId;
    if (produktkoordinatorId) this[UserRolle.PRODUKTKOORDINATOR] = produktkoordinatorId;
    if (mandantId) this[UserRolle.MANDANT] = mandantId;
    if (sachbearbeiterId) this[UserRolle.SACHBEARBEITER] = sachbearbeiterId;
  }

  /**
   * Creates Responsibilities from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const responsibilities = {};

    Responsibilities.ROLES.forEach((role) => {
      const v = value[role];
      if (v) {
        responsibilities[role] = v;
      }
    });

    return new Responsibilities(
      responsibilities[UserRolle.RECHTSANWALT],
      responsibilities[UserRolle.PARTNER],
      responsibilities[UserRolle.ADMINPARTNER],
      responsibilities[UserRolle.PRODUKTKOORDINATOR],
      responsibilities[UserRolle.MANDANT],
      responsibilities[UserRolle.SACHBEARBEITER]
    );
  }

  /**
   * @returns Responsibilities in JSON representation
   */
  toJSON() {
    const responsibilities = {};
    Responsibilities.ROLES.forEach((role) => {
      const v = this[role];
      if (v) {
        responsibilities[role] = v;
      }
    });

    return responsibilities;
  }

  hasRole(role) {
    return !!this[role];
  }

  get completeIds() {
    return Responsibilities.ROLES.every((role) => [
      null, undefined
    ].includes(this[role]) || validate(this[role]));
  }

  get notSamePartnerAndAdminPartner() {
    return (this[UserRolle.PARTNER] === null || this[UserRolle.ADMINPARTNER] === null)
    || this[UserRolle.PARTNER] !== this[UserRolle.ADMINPARTNER];
  }

  get valid() {
    const valid = this.completeIds
      && this.notSamePartnerAndAdminPartner;

    return valid;
  }
}
