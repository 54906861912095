<template>
  <div
    id="sentry-overflow"
    ref="sentryOverflow"
    class="d-none justify-center align-center gap-20 pa-15"
  >
    <div class="d-flex flex-row justify-center align-center gap-20">
      <v-icon
        size="100"
        class="icon-accent-orange-red"
      >
        $alertDiamond
      </v-icon>
      <span class="text-h3 color-accent-orange-red pa-4">
        Klicken Sie unten auf den Button, dass die Seite neu lädt,
        um danach weiterarbeiten zu können.
      </span>
      <v-icon
        size="100"
        class="icon-accent-orange-red"
      >
        $alertDiamond
      </v-icon>
    </div>
    <bbh-button
      color="var(--bbh-accent-orange-red)"
      @click="handleSentryOverflowClick"
    >
      <template #icon>
        <v-icon
          class="icon-white"
          left
        >
          $undo
        </v-icon>
      </template>
      Seite neu laden
    </bbh-button>
  </div>
</template>

<script>
export default {
  name: 'SentryOverflow',
  methods: {
    handleSentryOverflowClick() {
      this.$refs.sentryOverflow.classList.add('d-none');
      this.$router.go();
    }
  }
};
</script>

<style lang='scss' scoped>
#sentry-overflow {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  z-index: 990;

  & .v-icon .v-icon__component {
    --bbh-icon-color: var(--bbh-white);
  }
}
</style>

<style lang='scss'>
</style>
