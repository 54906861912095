import { isJSON } from '@st/utils-js';
import BausteinElementAttrs from './BausteinElementAttrs';
import Element from './Element';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';

export default class BausteinElement extends Element {
  /**
   *
   * @param {String} id UUIDs
   * @param {String} state value of ElementStatus
   * @param {BausteinElementAttrs} attrs
   * @param {*} content
   */
  constructor(
    id = null,
    state = ElementStatus.MODIFIABLE,
    attrs = new BausteinElementAttrs(),
    content = null,
    revision = 0
  ) {
    super(id, ElementTyp.BAUSTEIN, state, attrs, revision, content);
  }

  /**
   * Creates BausteinElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      state = ElementStatus.MODIFIABLE,
      attrs = new BausteinElementAttrs(),
      content = null,
      revision = 0
    } = value;

    return new BausteinElement(
      id,
      state,
      attrs,
      content,
      revision
    );
  }

  /**
   * @returns BausteinElement in JSON representation
   */
  toJSON() {
    let attrs = {};
    if (this.attrs instanceof BausteinElementAttrs) {
      attrs = this.attrs.toJSON();
    } else {
      attrs = this.attrs;
    }

    return {
      id: this.id,
      state: this.state,
      type: this.type,
      attrs,
      content: this.content,
      revision: this.revision
    };
  }

  get validRevision() {
    return typeof this.revision === 'number' && this.revision >= 0;
  }

  get valid() {
    const { validId } = this;
    const validType = !!this.type
      && typeof this.type === 'string'
      && ElementTyp.isBaustein(this.type);
    const validState = !!this.state
      && typeof this.state === 'string'
      && ElementStatus.isValidState(this.state);
    const validAttrs = !!this.attrs
      && this.attrs instanceof BausteinElementAttrs
      && this.attrs.valid;
    const validContent = !!this.content;

    const valid = validId
      && validType
      && validState
      && validAttrs
      && validContent
      && this.validRevision;

    return valid;
  }
}
