export default function sectionRefPath(element) {
  const refPath = element.ancestors
    .map((ancestor) => ancestor.refPathId)
    .filter((id) => !!id)
    .join('_');

  let key = element.refElement.id;
  if (refPath) {
    key = [ refPath ]
      .concat([ element.refElement.id ])
      .join('_');
  }

  return key;
}
