export default class AenderungsmodusMode {
  static ACCEPT_ALL = 'acceptAll';

  static ACCEPT_NONE = 'acceptNone';

  static ABORT = 'abort';

  static isValidState(state) {
    const states = [
      this.ACCEPT_ALL, this.ACCEPT_NONE, this.ABORT
    ];
    return states.includes(state);
  }

  static isStateAcceptAll(state) {
    return state === this.ACCEPT_ALL;
  }

  static isStateAcceptNone(state) {
    return state === this.ACCEPT_NONE;
  }

  static isStateAbort(state) {
    return state === this.ABORT;
  }
}
