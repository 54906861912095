import { uuid, validate } from '../utils/uuid';

export default class PersistObj {
  /**
   *
   * @param {String} id UUID
   */
  constructor(id = null) {
    let localId = id;
    if (!localId) {
      localId = uuid();
    }

    this.id = localId;
  }

  get validId() {
    return !!this.id && typeof this.id === 'string' && validate(this.id);
  }
}
