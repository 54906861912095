import config from './config';
import { get, post } from '../axios';

const { API_PREFIX } = config;

export default {
  /**
   * Fetches all workflow lists of the user
   * @returns
   */
  fetchLists() {
    const URL = `${API_PREFIX}/tasks`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} processKey from ProcessType
   * @param {JSON} variables
   * @returns Promise
   */
  start(processKey, elementId, type, revision, variables = undefined) {
    return new Promise((resolve, reject) => {
      // if (
      //   !processKey ||
      //   typeof processKey !== 'string' ||
      //   ProcessType.isValid(processKey) ||
      //   typeof variables !== 'object'
      // ) {
      //   reject(undefined);
      // }

      const URL = `${API_PREFIX}/startApprovalProcess`;

      const PAYLOAD = {
        processDefinitionKey: processKey,
        elementId,
        type,
        revision,
        variables
      };

      post(URL, PAYLOAD)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Completes a task
   * @param {String} taskId
   * @param {String} elementId
   * @param {Number} revision
   * @returns
   */
  complete(taskId, elementId, revision) {
    return new Promise((resolve, reject) => {
      const URL = `${API_PREFIX}/completeTask/${taskId}/${elementId}/${revision}/freigegeben`;
      post(URL, {
        // variables: {}
        // comments: []
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * Rejects the task to update the content and start the approval again
   * @param {String} taskId
   * @param {String} elementId
   * @param {Number} revision
   * @returns
   */
  adjust(taskId, elementId, revision) {
    return new Promise((resolve, reject) => {
      const URL = `${API_PREFIX}/completeTask/${taskId}/${elementId}/${revision}/überarbeiten`;
      post(URL, {
        // variables: {}
        // comments: []
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  /**
   * Rejects the task
   * @param {String} taskId
   * @param {String} elementId
   * @param {Number} revision
   * @returns
   */
  reject(taskId, elementId, revision) {
    return new Promise((resolve, reject) => {
      const URL = `${API_PREFIX}/completeTask/${taskId}/${elementId}/${revision}/abgelehnt`;
      post(URL, {
        // variables: {}
        // comments: []
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} taskId
   * @returns
   */
  claim(taskId) {
    const URL = `/workflow/claim/${taskId}`;
    const PAYLOAD = {
    };

    return new Promise((resolve, reject) => {
      post(URL, PAYLOAD)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} taskId
   * @returns
   */
  unclaim(taskId) {
    const URL = `/workflow/unclaim/${taskId}`;
    const PAYLOAD = {
    };

    return new Promise((resolve, reject) => {
      post(URL, PAYLOAD)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
};
