export default {
  group: 'block',
  content: 'paragraph+ | block*',
  attrs: {
    id: { default: null },
    clauseTransformation: { default: null },
    level: { default: 0 },
    subComponentLevelShifting: { default: 0 },
    comments: { default: [] }
  },
  toDOM: (node) => [
    'section', { id: node.attrs.id }, 0
  ],
  parseDOM: [
    {
      tag: 'section[id]',
      getAttrs: (dom) => {
        const id = dom.getAttribute('id');
        return { id };
      }
    }
  ]
};
