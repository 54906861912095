import { isJSON } from '@st/utils-js';
import PersistObj from './PersistObj';

export default class Tag extends PersistObj {
  constructor(
    id = null,
    name = '',
    color = ''
  ) {
    super(id);
    this.name = name;
    this.color = color;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      color: this.color
    };
  }

  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      clientTagId = null,
      name = '',
      color = ''
    } = value;

    return new Tag(
      id || clientTagId,
      name,
      color
    );
  }

  get validName() {
    return !!this.name && typeof this.name === 'string' && !!this.name.trim();
  }

  get validColor() {
    return !!this.color && typeof this.color === 'string' && !!this.color.trim();
  }

  get valid() {
    const valid = this.validId
      && this.validName
      && this.validColor;

    return valid;
  }
}
