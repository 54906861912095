import config from './config';
import {
  post
} from '../axios';
import conf from '../../../conf';

const { API_PREFIX } = config;

export default {
  /**
   * Fetch Diff version of a given elementId
   *
   * @param {String} clientElementId
   * @param {Object} clientBody
   * @returns {Promise}
   */
  getDiffVersion(clientElementId, clientBody) {
    const URL = `${API_PREFIX}/update/${clientElementId}`;

    return new Promise((resolve, reject) => {
      post(URL, clientBody)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   * get diff of clientElement with BbhStandard
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   * @param {String} singleAccessToken
   * @returns {String}
   */
  getMandantenOriginalDiffUrl(clientElementId, subElementId, singleAccessToken) {
    return `${conf.api}${API_PREFIX}/vergleichMitBbhStandard/${clientElementId}/${subElementId}`
      + `/${singleAccessToken}`;
  },

  /**
   * get diff of original bbh old and new version
   *
   * @param {String} clientElementId
   * @param {String} subElementId
   * @param {String} singleAccessToken
   * @returns {String}
   */
  getBbhDiffUrl(clientElementId, subElementId, singleAccessToken) {
    return `${conf.api}${API_PREFIX}/updateOriginal/${clientElementId}/${subElementId}/`
        + `${singleAccessToken}`;
  },

  /**
   * get diff of newest mandanten doc of old version and newest mandanten doc of new version
   *
   * @param {String} clientElementId1
   * @param {String} subElementId1
   * @param {String} clientElementId2
   * @param {String} subElementId2
   * @param {String} singleAccessToken
   * @returns {String}
   */
  getMandantenDiffUrl(
    clientElementId1,
    subElementId1,
    clientElementId2,
    subElementId2,
    singleAccessToken
  ) {
    return `${conf.api}${API_PREFIX}/updateMandant/${clientElementId1}/${subElementId1}/`
        + `${clientElementId2}/${subElementId2}/${singleAccessToken}`;
  }
};
