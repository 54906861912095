import state from './store/state';
import actions from './store/actions';
import mutations from './store/mutations';
import getters from './store/getters';

export default {
  strict: true,
  state,
  mutations,
  getters,
  actions
};
