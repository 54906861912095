import { deepClone } from '@st/utils-js';
import AbschnittKlauselTransformationCheckliste from '../../../../model/AbschnittKlauselTransformationCheckliste';
import FreigabeChecklist from '../../../../model/FreigabeChecklist';
import sectionRefPath from '../../../../utils/sectionRefPath';
import types from '../../../types';

const sharedEditorMutations = types.mutations.sharedEditor;

export default {
  // SET
  [sharedEditorMutations.SET_COMMENT](state, comment) {
    state.comment = comment;
  },
  [sharedEditorMutations.SET_REFERENCE](state, reference) {
    state.reference = reference;
  },

  [sharedEditorMutations.SET_FOCUSED_SECTION](state, { focusedSection, close }) {
    if (close) {
      const attr = state.focusedSection.refPathId ? 'refPathId' : 'sectionId';

      if (focusedSection[attr] === state.focusedSection[attr]) {
        state.focusedSection = {};
      }
    } else if (!close) {
      state.focusedSection = deepClone(focusedSection);
    }
  },

  [sharedEditorMutations.UPDATE_FOCUSED_SECTION_ATTRS](state, { attrs }) {
    const updatedAbschnittKlauselTransformationCheckliste = {
      ...state.focusedSection.refElement.attrs.clauseTransformation,
      ...attrs.clauseTransformation
    };
    const mappedClauseTransformation = AbschnittKlauselTransformationCheckliste.fromJSON(
      updatedAbschnittKlauselTransformationCheckliste
    );

    state.focusedSection = {
      ...state.focusedSection,
      refElement: {
        ...state.focusedSection.refElement,
        attrs: {
          ...state.focusedSection.refElement.attrs,
          ...attrs,
          clauseTransformation: mappedClauseTransformation
        }
      }
    };
  },
  [sharedEditorMutations.SET_SECTION_RERENDER_MAP](state, { sectionRerenderMap }) {
    state.sectionRerenderMap = sectionRerenderMap;
  },

  [sharedEditorMutations.SET_FREIGABE_STATUS](state, status) {
    state.freigabe.status = status;
  },

  [sharedEditorMutations.SET_FREIGABE_IS_APPROVAL](state, isApproval) {
    state.freigabe.isApproval = isApproval;
  },

  [sharedEditorMutations.SET_FREIGABE_TASKID](state, id) {
    state.freigabe.taskId = id;
  },

  [sharedEditorMutations.RESET_FREIGABEPROZESS](state) {
    state.checklist = {};
    state.freigabe = {
      isApproval: false,
      status: null,
      taskId: null
    };
  },

  [sharedEditorMutations.SET_CHECKLIST](state, {
    checklist,
    revision
  }) {
    let preparedChecklist = checklist;
    if (!(checklist instanceof FreigabeChecklist)) {
      preparedChecklist = FreigabeChecklist.fromJSON(checklist, revision);
    }
    state.checklist = preparedChecklist;
  },

  [sharedEditorMutations.TOGGLE_CHECKLIST_CHECKED](state, {
    key,
    revision
  }) {
    toggleChecklistChecked(state, key, revision);
  },

  [sharedEditorMutations.TOGGLE_CHECKLIST_SECTION_CHECKED](state, {
    section,
    revision
  }) {
    const key = sectionRefPath(section);

    toggleChecklistChecked(state, key, revision);
  },

  [sharedEditorMutations.SET_GROUP_SECTIONS_MODE](state, mode) {
    state.groupSectionsMode = mode;
  },

  // UPDATE

  [sharedEditorMutations.UPDATE_CHECKLIST_CHECKED_EXCEPT](state, {
    exceptKey,
    oldRevision,
    revision
  }) {
    state.checklist.updateRevisions([ exceptKey ], oldRevision, revision);
  },

  // UPDATE

  [sharedEditorMutations.UPDATE_REFERENCE](state, reference) {
    state.reference = reference;
  },

  [sharedEditorMutations.INCREASE_UPDATE_VALUE_COUNTER](state) {
    state.updateValueCounter += 1;
  },

  [sharedEditorMutations.INCREASE_RESET_TO_REVISION_COUNTER](state) {
    state.resetToRevisionCounter += 1;
  },

  [sharedEditorMutations.INCREASE_RELOAD_ELEMENT_COUNTER](state) {
    state.reloadElementCounter += 1;
  },

  [sharedEditorMutations.INCREASE_ELEMENT_TAB_TRIGGER](state, { tabEntry }) {
    state.elementTabTrigger.value += 1;
    state.elementTabTrigger.tabEntry = tabEntry;
  },

  /**
   *
   * @param {String} id als refPathId oder sectionId
   */
  [sharedEditorMutations.UPDATE_SECTION_RERENDER_MAP](state, { id }) {
    state.sectionRerenderMap[id] += 1;
  },

  [sharedEditorMutations.UPDATE_COMPARE_MODE](state, { mode = 'text' }) {
    state.compareMode = mode;
  },

  [sharedEditorMutations.SET_LAST_SAVED_REQUEST_PAYLOAD](state, { payload }) {
    state.lastSavedRequestPayload = payload;
  },

  // ADD

  // REMOVE

  // DEPRECATE

  // DELETE

  // PUT

  // CLEAR
  [sharedEditorMutations.CLEAR_COMMENT](state) {
    state.comment = {};
  },
  [sharedEditorMutations.CLEAR_REFERENCE](state) {
    state.reference = {};
  }
};

function toggleChecklistChecked(state, key, revision) {
  const checklist = state.checklist.toJSON();
  const copy = FreigabeChecklist.fromJSON(checklist, state.checklist.revision);
  copy.toggleChecked(key, revision);

  state.checklist = copy;
}
