import editor from './editor';
import status from './status';
import user from './user';
import workflow from './workflow';
import vergleich from './vergleich';
import tests from './tests';
import shop from './shop';
import mandant from './mandant';
import client from './client';

export default {
  editor,
  status,
  user,
  workflow,
  vergleich,
  tests,
  shop,
  mandant,
  client
};
