import { component as MODULE } from '../store/state';

const GETTERS = {
  COMMENT: `${MODULE}comment`,
  REFERENCE: `${MODULE}reference`,

  FOCUSED_SECTION: `${MODULE}focused_section`,

  SECTION_RERENDER_MAP: `${MODULE}section_rerender_map`,
  ELEMENT_TAB_TRIGGER: `${MODULE}element_tab_trigger`,

  FREIGABE_STATUS: `${MODULE}freigabe_status`,

  FREIGABEPROZESS_CHECKLIST: `${MODULE}freigabeprozes_checklist`,
  FREIGABEPROZESS_CHECKLIST_PROZESS: `${MODULE}freigabeprozes_checklist_prozess`,
  FREIGABEPROZESS_CHECKLIST_CHECKED: `${MODULE}freigabeprozes_checklist_checked`,
  FREIGABEPROZESS_CHECKLIST_SECTION_CHECKED: `${MODULE}freigabeprozes_checklist_section_checked`,

  UPDATE_VALUE_COUNTER: `${MODULE}update_value_counter`,
  RESET_TO_REVISION_COUNTER: `${MODULE}reset_to_revision_counter`,

  COMPARE_MODE: `${MODULE}compare_mode`
};

export default GETTERS;
