import { isJSON } from '@st/utils-js';
import { uuid, validate } from '../utils/uuid';
import AntwortTyp from './enums/AntwortTyp';

export default class Antwort {
  /**
   *
   * @param {String} id UUID
   * @param {String} type value of AntwortTyp
   * @param {String} text
   * @param {String} internalComment optional
   * @param {String} externalComment optional
   * @param {Array} choiceIds Array of Entscheidung
   */
  constructor(
    id = null,
    type = AntwortTyp.ANSWER,
    text = '',
    internalComment = null,
    externalComment = null,

    choiceIds = []
  ) {
    this.type = type;
    this.text = text;
    this.attrs = {
      id: id || uuid(),
      internalComment,
      externalComment,
      choiceIds
    };
  }

  /**
   * @returns cloned Antwort with new ID
   */
  clone() {
    return new Antwort(
      undefined,
      this.type,
      this.text,
      this.attrs.internalComment,
      this.attrs.externalComment,
      this.attrs.choiceIds
    );
  }

  /**
   * Creates Antwort from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      type = AntwortTyp.ANSWER,
      text = '',
      attrs = {
        id: null,
        choiceIds: [],
        internalComment: '',
        externalComment: ''
      }
    } = value;

    const newId = attrs.id || uuid();

    return new Antwort(
      newId,
      type,
      text,
      attrs.internalComment,
      attrs.externalComment,
      attrs.choiceIds
    );
  }

  /**
   * @returns Antwort in JSON representation
   */
  toJSON() {
    return {
      text: this.text,
      type: this.type,
      attrs: this.attrs
    };
  }

  get validType() {
    return !!this.type
      && typeof this.type === 'string'
      && AntwortTyp.isValidState(this.type);
  }

  get validText() {
    return typeof this.text === 'string' && !!this.text.trim();
  }

  get validInternalComment() {
    return this.attrs.internalComment === null || typeof this.attrs.internalComment === 'string';
  }

  get validExternalComment() {
    return this.attrs.externalComment === null || typeof this.attrs.externalComment === 'string';
  }

  get validChoiceIds() {
    return Array.isArray(this.attrs.choiceIds)
      && this.attrs.choiceIds.every((choiceId) => validate(choiceId));
  }

  get validId() {
    return validate(this.attrs.id);
  }

  get valid() {
    const valid = this.validId
      && this.validType
      && this.validText
      && this.validInternalComment
      && this.validExternalComment
      && this.validChoiceIds;

    return valid;
  }
}
