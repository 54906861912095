import types from '../types/mutation-types';

export default {
  [types.SET_SUB_HEADER_LOADING](state, value) {
    state.subHeaderLoading = value;
  },

  [types.SUB_HEADER_ACTION_BAR](state, { subHeaderActionBar }) {
    state.subHeaderActionBar = subHeaderActionBar;
  },
  [types.RESET_SUB_HEADER_ACTION_BAR](state, { subHeaderActionBarComponent }) {
    if (state.subHeaderActionBarComponent !== subHeaderActionBarComponent) return;

    state.subHeaderActionBar = [];
  },

  [types.SET_SUB_HEADER_ACTION_BAR_COMPONENT](state, { subHeaderActionBarComponent }) {
    state.subHeaderActionBarComponent = subHeaderActionBarComponent;
  },

  [types.NEUES_DOKUMENT]() {},
  [types.NEUES_HASHTAG]() {},
  [types.NEUE_ENTSCHEIDUNG]() {},

  [types.VERTRAG_BEARBEITEN]() {},
  [types.KOLLEKTIONEN](state, payload) {
    state.kollektionen = payload;
  },
  [types.SET_KOLLEKTIONEN_BEARBEITEN](state, payload) {
    state.kollektionenBearbeiten = payload;
  },

  [types.APPROVE_CONTRACT]() {},

  [types.NEUE_AUFGABE]() {},

  [types.SET_NEW_MUSTERELEMENT](state, value) {
    state.newMusterelement = value;
  },

  [types.SET_MIGRATION](state, value) {
    state.migration = value;
  },

  [types.SET_INDIVIDUELLER_VERTRAG](state, value) {
    state.individuellerVertrag = value;
  },

  [types.EDIT_CONTRACT](state, value) {
    state.editContract += value;
  },

  [types.PAKETVERWALTUNG](state, value) {
    state.paketverwaltung = value;
  },

  [types.SET_FRAGEBOGEN_BEARBEITEN](state, value) {
    state.fragebogenBearbeiten = value;
  },
  [types.SET_FRAGEBOGEN](state, value) {
    state.fragebogen = value;
  },

  [types.SET_SERVICEZUGANG_DIALOG](state, value) {
    state.servicezugangDialog = value;
  }
};
