import AenderungsmodusPatch from './AenderungsmodusPatch';

export default class AenderungsmodusSection {
  /**
   * @param {number} baseRevision
   * @param {number} baseSubRevision
   * @param {AenderungsmodusPatch[]} patches
   */
  constructor(
    baseRevision,
    baseSubRevision,
    patches = []
  ) {
    const mappedPatches = (Array.isArray(patches) ? patches : [])
      .map((patch) => new AenderungsmodusPatch(
        patch.userId,
        patch.firstname,
        patch.lastname,
        patch.modified,
        patch.patch,
        patch.state,
        patch.revision,
        patch.subRevision,
        patch.comments,
        patch.updatePatch
      ));
    this.patches = mappedPatches;
    if (typeof baseRevision !== 'number' || baseRevision < 1) {
      throw new Error('Invalid baseRevision');
    }
    this.baseRevision = baseRevision;
    if (typeof baseSubRevision !== 'number' || baseSubRevision < 0) {
      throw new Error('Invalid baseSubRevision');
    }
    this.baseSubRevision = baseSubRevision;
  }

  static fromJSON(json) {
    if (!json || typeof json !== 'object') {
      return null;
    }
    if (json instanceof AenderungsmodusSection) return json;

    return new AenderungsmodusSection(
      json.baseRevision,
      json.patches
    );
  }

  get validPatches() {
    return !!this.patches && Array.isArray(this.patches)
      && this.patches.every((patch) => patch instanceof AenderungsmodusPatch && patch.valid);
  }

  get validBaseRevision() {
    return !!this.baseRevision && typeof this.baseRevision === 'number'
      && this.baseRevision > 0;
  }

  get validBaseSubRevision() {
    return !!this.baseSubRevision && typeof this.baseSubRevision === 'number'
      && this.baseSubRevision >= 0;
  }

  get valid() {
    return this.validPatches
      && this.validBaseRevision
      && this.validBaseSubRevision;
  }
}
