import { validate } from '@src/utils/uuid';
import AenderungsmodusComment from './AenderungsmodusComment';
import AenderungsmodusSectionState from '../enums/AenderungsmodusSectionState';

export default class AenderungsmodusPatch {
  /**
   *
   * @param {String} userId
   * @param {String} firstname
   * @param {String} lastname
   * @param {Number} modified
   * @param {Patches[]} patch
   * @param {AenderungsmodusSectionState} state
   * @param {Number} revision
   * @param {Number} subRevision
   * @param {AenderungsmodusComment[]} comments
   * @param {[0, 1]} updatePatch
   */
  constructor(
    userId,
    firstname,
    lastname,
    modified,
    patch,
    state,
    revision,
    subRevision,
    comments = [],
    updatePatch = 0
  ) {
    this.userId = userId;
    this.firstname = firstname;
    this.lastname = lastname;
    this.modified = modified;
    this.patch = patch;
    if (!AenderungsmodusSectionState.isValidState(state)) {
      throw new Error('Invalid state');
    }
    this.state = state;
    if (typeof revision !== 'number' || revision < 0) {
      throw new Error('Invalid revision');
    }
    this.revision = revision;
    if (typeof subRevision !== 'number' || subRevision < 0) {
      throw new Error('Invalid subRevision');
    }
    this.subRevision = subRevision;
    const mappedComments = comments
      .map((comment) => new AenderungsmodusComment(
        comment.keycloakUserUuid,
        comment.comment,
        comment.created,
        comment.firstname,
        comment.lastname
      ));
    if (!mappedComments.every((comment) => comment.valid)) {
      throw new Error('Invalid comments');
    }
    this.comments = mappedComments;
    if (![
      0, 1
    ].includes(updatePatch)) {
      throw new Error('Invalid updatePatch');
    }
    this.updatePatch = updatePatch;
  }

  /**
   * {
   *   userId as {String},
   *   firstname as {String},
   *   lastname as {String},
   *   modified as {Number},
   *   patch as {Patches[]},
   *   state as {AenderungsmodusSectionState},
   *   revision as {Number},
   *   subRevision as {Number},
   *   comments as {AenderungsmodusComment[]},
   *   updatePatch as {Boolean},
   * }
   */
  static fromJSON(json) {
    if (!json || typeof json !== 'object') {
      return null;
    }
    if (json instanceof AenderungsmodusPatch) return json;

    return new AenderungsmodusPatch(
      json.userId,
      json.firstname,
      json.lastname,
      json.modified,
      json.patch,
      json.state,
      json.revision,
      json.subRevision,
      json.comments,
      json.updatePatch
    );
  }

  get validUserId() {
    return !!this.userId && typeof this.userId === 'string' && validate(this.userId);
  }

  get validFirstname() {
    return !!this.firstname && typeof this.firstname === 'string';
  }

  get validLastname() {
    return !!this.lastname && typeof this.lastname === 'string';
  }

  get validModified() {
    return !!this.modified && typeof this.modified === 'number';
  }

  get validPatch() {
    return !!this.patch && Array.isArray(this.patch);
  }

  get validState() {
    return !!this.state && AenderungsmodusSectionState.isValidState(this.state);
  }

  get validRevision() {
    return !!this.revision && typeof this.revision === 'number'
      && this.revision > 0;
  }

  get validSubRevision() {
    return !!this.subRevision && typeof this.subRevision === 'number'
      && this.subRevision >= 0;
  }

  get validComments() {
    return !!this.comments && Array.isArray(this.comments)
      && this.comments.every((comment) => comment instanceof AenderungsmodusComment
        && comment.valid);
  }

  get validUpdatePatch() {
    return typeof this.updatePatch === 'number' && [
      0, 1
    ].includes(this.updatePatch);
  }

  get valid() {
    return this.validUserId
      && this.validFirstname
      && this.validLastname
      && this.validModified
      && this.validPatch
      && this.validState
      && this.validRevision
      && this.validSubRevision
      && this.validComments
      && this.validUpdatePatch;
  }
}
