import CompareMode from '@src/model/enums/CompareMode';

export default {
  comment: {},
  reference: {},
  focusedSection: {},
  sectionRerenderMap: {},

  updateValueCounter: 0,
  resetToRevisionCounter: 0,
  reloadElementCounter: 0,
  elementTabTrigger: {
    value: 0,
    tabEntry: ''
  },

  freigabe: {
    isApproval: false,
    status: null,
    taskId: null
  },

  checklist: {},

  compareMode: CompareMode.TEXT,

  lastSavedRequestPayload: {},

  groupSectionsMode: false
};

export const component = 'sharedEditor/';
