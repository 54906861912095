export default function secretGuard(to, from, next) {
  if (to.name === 'SecretView') {
    if (from.name === 'Home') {
      next();
      return;
    }
    next({ name: 'Home' });
    return;
  }
  next();
}
