import AenderungsmodusSection from '@src/model/aenderungsmodus/AenderungsmodusSection';
import AenderungsmodusSectionState from '@src/model/enums/AenderungsmodusSectionState';
import { isEmptyOrNullish } from '@st/utils-js';

function aenderungsmodusHeadIsSetAndRevisionOfSectionIsNotLatest(
  aenderungsmodusSelectedHead,
  historySection
) {
  if (isEmptyOrNullish(aenderungsmodusSelectedHead)) return false;

  if (historySection == null) return false;

  if (!(historySection instanceof AenderungsmodusSection)) throw new Error('invalid historySection');
  return aenderungsmodusSelectedHead.revision < historySection.baseRevision;
}

function getAenderungsmodusSectionState(sectionId, newOrder, oldOrder, aenderungsmodusHistory) {
  const sectionWasDeleted = newOrder.length < oldOrder.length;

  const historySection = aenderungsmodusHistory?.sections?.[sectionId];
  if (
    !historySection
        || historySection.patches.length < 1
  ) {
    if (sectionWasDeleted) {
      return AenderungsmodusSectionState.EXISTING_DELETED;
    }
    return oldOrder.includes(sectionId)
      ? AenderungsmodusSectionState.EXISTING
      : AenderungsmodusSectionState.NEW;
  }

  const historySectionState = historySection.patches[0].state;

  const sectionIsInNewOrder = newOrder.includes(sectionId);
  if (!sectionIsInNewOrder) {
    if (AenderungsmodusSectionState.isNew(historySectionState)) {
      return AenderungsmodusSectionState.NEW_DELETED;
    }
    return sectionWasDeleted
      ? AenderungsmodusSectionState.EXISTING_DELETED
      : AenderungsmodusSectionState.EXISTING;
  }

  return AenderungsmodusSectionState.isExistingDeleted(historySectionState)
    ? AenderungsmodusSectionState.EXISTING
    : historySectionState;
}

export {
  aenderungsmodusHeadIsSetAndRevisionOfSectionIsNotLatest,
  getAenderungsmodusSectionState
};
