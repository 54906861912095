import config from './config';
import { get, put } from '../axios';

const { API_PREFIX } = config;

const PREFERENCES = [];

export default {
  PREFERENCES,
  /**
   *
   * @param {String} path
   * @returns
   */
  fetch(path) {
    const URL = `${API_PREFIX}/preference/${path}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param {String} path
   * @param {JSON} settings
   * @returns
   */
  update(path, settings) {
    const URL = `${API_PREFIX}/preference/${path}`;

    return new Promise((resolve, reject) => {
      put(URL, settings)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
};
