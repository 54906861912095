<template>
  <div class="text-center floating-btn">
    <v-menu
      top
      offset-y
      content-class="bbh-floating-btn-menu-wrapper"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          id="bbh-floating-btn"
          fab
          v-bind="attrs"
          color="var(--bbh-red)"
          v-on="on"
          @click="$emit('click')"
          @click.shift="consoleLogVersion"
        >
          <v-icon>
            $bbhLogo
          </v-icon>
        </v-btn>
      </template>
      <div id="bbh-floating-btn-menu">
        <v-btn
          v-for="(item, index) in items"
          :key="index"
          icon
          @click="handleIconClicked(item.icon)"
        >
          <bbh-tooltip
            :bottom="true"
            :open-delay="500"
          >
            <template #activator>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </template>
            <span>{{ item.text }}</span>
          </bbh-tooltip>
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import types from '../../store/types';
import { version } from '../../../package.json';

const userGetters = types.getters.user;

export default {
  name: 'FloatingButton',
  data: () => ({
    items: [
      { icon: '$phoneAlt', text: 'Anruf anfordern' },
      { icon: '$emailCustom', text: 'Email an Ansprechpartner' }
    ],

    appVersion: version
  }),
  computed: {
    ...mapGetters({ currentUser: userGetters.USER })
  },
  methods: {
    handleIconClicked(event) {
      switch (event) {
      case '$phoneAlt':
        window.open('mailto:kontakt@bbh-solutions.de?subject=BBHS%2FAnrufbitte&body=Liebes%20BBHS-Team!%0D%0A%0D%0AIch%20bitte%20um%20Anruf%20unter%3A%20%0D%0A%5BBitte%20Telefonnummer%20angeben%5D.%20%0D%0A%0D%0AIch%20bin%20am%20besten%20erreichbar%3A%20%0D%0A%5BTag%2C%20Uhrzeit%20%E2%80%93%20Geben%20Sie%20uns%20dazu%20am%20besten%202%20oder%203%20Zeitfenster%20an%2C%20in%20denen%20wir%20Sie%20gut%20erreichen%20k%C3%B6nnen%5D%20%0D%0A%0D%0AMein%20Anliegen%3A%20%0D%0A%5BBitte%20schildern%20Sie%20Ihr%20Anliegen%20m%C3%B6glichst%20konkret%2C%20damit%20wir%20die%20richtigen%20Ansprechpartner*innen%20f%C3%BCr%20Sie%20finden.%5D');
        break;
      case '$emailCustom':
        window.open('mailto:kontakt@bbh-solutions.de?subject=BBHS%20Supportanfrage&body=Liebes%20BBHS-Team!%0D%0A%0D%0AMein%20Anliegen%3A%0D%0A%5BBitte%20schildern%20Sie%20Ihr%20Anliegen%20m%C3%B6glichst%20konkret.%5D');
        break;

      default:
        break;
      }
    },

    consoleLogVersion() {
      console.log(`Version: %c${this.appVersion}`, 'color: #007D55; font-weight: bold; font-size: 1rem');
    }
  }
};
</script>

<style lang="scss">
#bbh-floating-btn.v-btn--fab.v-size--default{
  position: fixed;
  bottom: 35px;
  right: 35px;

  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.25);

  width: 50px;
  height: 50px;

  & .v-icon .v-icon__component {
    --bbh-icon-color: var(--bbh-white);
  }
}

.bbh-floating-btn-menu-wrapper.v-menu__content {
  transform: translate(-34px, 17px);

  width: max-content;
  padding: 5px 0;

  & #bbh-floating-btn-menu {
    display: grid;
    grid-template-rows: 50px 50px;
    grid-template-columns: 45px;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;

    min-width: unset;
    width: 100%;

    margin: 0;

    & .v-icon .v-icon__component {
      --bbh-icon-color: var(--bbh-black);
      width: 23px;
      height: 23px;

    }
  }
}
</style>
