const defaultAttrs = { value: false, disabled: false };

function generateCheckbox(attrs = defaultAttrs) {
  const inputElement = document.createElement('input');
  inputElement.setAttribute('type', 'checkbox');
  inputElement.innerText = '_';

  const { value: checked = false, disabled = true } = attrs;

  inputElement.checked = checked;
  inputElement.disabled = disabled;

  return inputElement;
}

export {
  generateCheckbox
};

export default {
  inline: true,
  group: 'inline',
  marks: '',
  attrs: {
    value: { default: false },
    disabled: { default: true }
  },
  toDOM: (node) => generateCheckbox(node.attrs),

  parseDOM: [
    {
      tag: 'input'
    }
  ]
};
