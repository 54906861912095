<template>
  <div
    :class="{
      overlay: overlay,
      filled: filled,
    }"
  >
    <div
      class="loading-shadow"
      :class="{ relative: relative }"
    >
      <div
        class="custom-loading-icon"
        :class="{ relative: relative }"
      >
        <v-icon id="bbh-loading-logo">
          $bbhLogo
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BbhLoadingSpinner',
  props: {
    relative: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang='scss'>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;

  width: 100vw;
  height: 100vh;

  background: #a4a4a44d;
}

.filled {
  position: fixed;
  top: 140px;
  left: 0;
  z-index: 9;

  width: 100%;
  height: 100%;
  min-height: 250px;
}

.custom-loading-icon {
  position:fixed;
  z-index: 10;

  &.relative{
    position: relative;
    top: 0;
    left: 0;
  }

  top: calc(50% - 20px);
  left: calc(50% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  height: max-content;

  padding: 15px;

  background: white;

  border-radius: 15px;

  overflow: hidden;

  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),
    0 6px 10px 0 rgba(0,0,0,.14),
    0 1px 18px 0 rgba(0,0,0,.12);

  &::before {
    content: "";
    position: absolute;
    width: 40%;
    height: 150%;
    background: linear-gradient(var(--bbh-red) 50%, var(--bbh-grey) 50%);
    animation: borderAnimation 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 4px;
    background: white;
    border-radius: 15px;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.08),
      inset 0px 4px 14px rgba(0, 0, 0, 0.03);
  }

  & #bbh-loading-logo.v-icon .v-icon__component {
    position: relative;
    --bbh-icon-color: var(--bbh-red);
    width: 40px;
    height: 40px;

    z-index: 10;
  }
}

.loading-shadow {
  position:fixed;
  z-index: 10;

  &.relative{
    position: relative;
    top: 0;
    left: 0;
  }

  top: calc(50% - 20px);
  left: calc(50% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70px;
  height: 70px;

  &::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    padding: 18px;
    border-radius: 5px;
    /* background: linear-gradient(to bottom, red, grey); */
    -webkit-animation: borderAnimation 4s linear infinite;
    animation: borderAnimation 4s linear infinite;
    box-shadow: 0px -24px 39px -10px rgb(220 12 35 / 10%),
      0px -54px 33px -4px rgb(220 12 35 / 10%),
      0px 24px 39px -10px rgb(95 94 94 / 10%),
      0px 54px 33px -4px rgb(95 94 94 / 10%);
  }
}

@keyframes borderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
