import types from '../../../types';

const mandantEditorGetters = types.getters.mandantEditor;

export default {
  [mandantEditorGetters.CLIENT_ELEMENT]: (state) => state.clientElement,
  [mandantEditorGetters.ALL_CLIENT_ELEMENTS]: (state) => state.clientElements,
  [mandantEditorGetters.CURRENT_CLIENT_ELEMENT_ID]: (state) => state.currentClientElementId,
  [mandantEditorGetters.CURRENT_CLIENT_ELEMENT_NAME]: (state, getters) => {
    const currentClientElementId = getters[mandantEditorGetters.CURRENT_CLIENT_ELEMENT_ID];
    return state.clientElement?.clientElement?.subElements[currentClientElementId]?.name ?? '';
  },
  [mandantEditorGetters.MANDANT_COMPARE_ELEMENT]: (state) => state.mandantCompareElement ?? {},

  [mandantEditorGetters.GET_AENDERUNGSMODUS_HISTORY_SECTION]: (state) => (sectionId) => state
    .aenderungsmodusHistory?.sections[sectionId]
};
