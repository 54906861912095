export default class ElementTyp {
  static KLAUSEL = 'KLAUSEL';

  static MUSTERDOKUMENT = 'MUSTERDOKUMENT';

  static MUSTERVERTRAG = 'MUSTERVERTRAG';

  static BAUSTEIN = 'BAUSTEIN';

  static DOKUMENT = 'DOKUMENT';

  static ABSCHNITT = 'ABSCHNITT';

  static UEBERSCHRIFT = 'UEBERSCHRIFT';

  static SUBELEMENT = 'SUBELEMENT';

  static PHRASE = 'PHRASE';

  static isType(type, key) {
    if (!this.isValid(key)) return false;
    return type === this[key];
  }

  static isValid(elementType) {
    return typeof this[elementType] === 'string';
  }

  static isLeafType(type) {
    return [
      this.ABSCHNITT, this.UEBERSCHRIFT
    ].includes(type);
  }

  static isPhrase(type) {
    return this.isType(type, this.PHRASE);
  }

  static isKlausel(type) {
    return this.isType(type, this.KLAUSEL);
  }

  static isMusterdokument(type) {
    return this.isType(type, this.MUSTERDOKUMENT);
  }

  static isDokument(type) {
    return this.isType(type, this.DOKUMENT);
  }

  static isMustervertrag(type) {
    return this.isType(type, this.MUSTERVERTRAG);
  }

  static isBaustein(type) {
    return this.isType(type, this.BAUSTEIN);
  }

  static isAbschnitt(type) {
    return this.isType(type, this.ABSCHNITT);
  }

  static isUeberschrift(type) {
    return this.isType(type, this.UEBERSCHRIFT);
  }

  static isSubelement(type) {
    return this.isType(type, this.SUBELEMENT);
  }
}
