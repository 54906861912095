import PersistObj from './PersistObj';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';

export default class Element extends PersistObj {
  /**
   *
   * @param {String} id UUID
   * @param {String} type value of ElementTyp
   * @param {String} state value of ElementStatus
   * @param {JSON} attrs
   * @param {*} content
   */
  constructor(
    id = null,
    type = '',
    state = '',
    attrs = null,
    revision = '',
    content = null
  ) {
    super(id);
    this.type = type;
    this.state = state;
    this.attrs = attrs;
    this.revision = revision;
    this.content = content;
  }

  get validType() {
    return !!this.type && typeof this.type === 'string' && ElementTyp.isValid(this.type);
  }

  get validState() {
    return !!this.state && typeof this.state === 'string' && ElementStatus.isValidState(this.state);
  }
}
