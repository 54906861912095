import { component as MODULE } from '../store/state';

const ACTIONS = {
  FETCH_ELEMENT: `${MODULE}fetch_element`,
  FETCH_MUSTERVERTRAG: `${MODULE}fetch_mustervertrag`,
  FETCH_ELEMENT_AND_RETURN: `${MODULE}fetch_element_and_return`,

  FETCH_RELATIONS: `${MODULE}fetch_relations`,
  UPDATE_COMPARE_ELEMENT: `${MODULE}update_compare_element`,

  FETCH_REVISIONS: `${MODULE}fetch_revisions`,

  PATCH_CREATE_DESCENDANT: `${MODULE}patch_create_descendant`,
  PATCH_CREATE_SUB_ELEMENT_DESCENDANT: `${MODULE}patch_create_sub_element_descendant`,
  PATCH_REPLACE_WITH_NEWEST_APPROVED_VERSION: `${MODULE}patch_replace_with_newest_approved_version`,

  CHECK_IF_INTERNAL_REFERENCE_EXISTS: `${MODULE}check_if_internal_reference_exists`,
  CHECK_IF_ELEMENT_REFERENCE_EXISTS: `${MODULE}check_if_element_reference_exists`,

  DELETE_ELEMENT: `${MODULE}delete_element`
};

export default ACTIONS;
