import { deepClone } from '@st/utils-js';
import ElementTyp from '../../../../model/enums/ElementTyp';
import ElementFactory from '../../../../utils/ElementFactory';
import editorGetters from '../types/getter-types';

function getLevelKey(level) {
  return `level${(level)}`;
}

export default {
  [editorGetters.CURRENT_MASTER_CONTRACT]: (state) => {
    const id = state.element.editorElement;
    if (!id) return {};
    const model = buildModel(id, deepClone(state.element));
    return model ?? {};
  },
  [editorGetters.MASTER_ELEMENT]: (state) => (indicator = ElementTyp.MUSTERDOKUMENT) => {
    const { editorElement } = state.element;
    if (!editorElement) return {};

    const element = state.element.elements[editorElement];

    if (element.type === indicator) {
      return element;
    }
    if (element.type === ElementTyp.MUSTERVERTRAG && indicator === ElementTyp.MUSTERDOKUMENT) {
      const subElement = element.content.find((doc) => doc.id === state.currentDocId);
      const masterElement = state.element.elements[subElement.id];
      return masterElement;
    }

    return {};
  },

  [editorGetters.BUILD_MODEL]: (state) => (currentDocId) => {
    const id = currentDocId || state.currentDocId;
    if (!id) return {};

    return buildModel(id, deepClone(state.element));
  },

  [editorGetters.NUMBERING]: (state) => (
    isKlause = false,
    sections = state.element.compiledContent
  ) => {
    const localCounter = {};
    let maxLevel;
    let currentAnchor = isKlause ? 0 : undefined;
    let labelVisible = isKlause ? 1 : 0;

    const labels = {};

    sections.forEach(({ refElement: section, refPathId }) => {
      let { level } = section.attrs;

      const { type } = section;

      const showEntry = level !== 0;

      if (ElementTyp.isKlausel(type)) {
        if (level > 0) {
          currentAnchor = level;
          labelVisible = 1;
        } else labelVisible = 0;

        if (
          localCounter[getLevelKey(level)] === null
          || localCounter[getLevelKey(level)] === undefined
        ) {
          Array.from(
            {
              length: level + 1
            },
            (v, k) => {
              if (
                localCounter[getLevelKey(k)] === null
                || localCounter[getLevelKey(k)] === undefined
              ) localCounter[getLevelKey(k)] = 1;
              return null;
            }
          );
          if (level > 0) maxLevel = level;
        } else {
          localCounter[getLevelKey(level)] += 1;

          Array.from(
            {
              length: maxLevel + 1
            },
            (v, k) => {
              if (k > level) {
                delete localCounter[getLevelKey(k)];
              }
              return null;
            }
          );
        }
      } else {
        switch (type) {
        case ElementTyp.UEBERSCHRIFT:
          if (level > 0) {
            currentAnchor = level;
            labelVisible = 1;
          } else labelVisible = 0;
          if (
            localCounter[getLevelKey(level)] === null
                || localCounter[getLevelKey(level)] === undefined
          ) {
            Array.from(
              {
                length: level + 1
              },
              (v, k) => {
                if (
                  localCounter[getLevelKey(k)] === null
                      || localCounter[getLevelKey(k)] === undefined
                ) localCounter[getLevelKey(k)] = 1;
                return null;
              }
            );
            if (level > 0) maxLevel = level;
          } else {
            localCounter[getLevelKey(level)] += 1;

            Array.from(
              {
                length: maxLevel + 1
              },
              (v, k) => {
                if (k > level) {
                  delete localCounter[getLevelKey(k)];
                }
                return null;
              }
            );
          }
          break;
        case ElementTyp.ABSCHNITT:
        case ElementTyp.KLAUSEL:
          level = currentAnchor ? level + currentAnchor : level;
          if (labelVisible && showEntry) {
            if (
              localCounter[getLevelKey(level)] === null
                  || localCounter[getLevelKey(level)] === undefined
            ) {
              Array.from(
                {
                  length: level + 1
                },
                (v, k) => {
                  if (
                    localCounter[getLevelKey(k)] === null
                        || localCounter[getLevelKey(k)] === undefined
                  ) localCounter[getLevelKey(k)] = 1;
                  return null;
                }
              );
              maxLevel = level;
            } else {
              localCounter[getLevelKey(level)] += 1;

              Array.from(
                {
                  length: maxLevel + 1
                },
                (v, k) => {
                  if (k > level) {
                    delete localCounter[getLevelKey(k)];
                  }
                  return null;
                }
              );
            }
          }
          break;
        default:
          break;
        }
      }

      delete localCounter.level0;

      const ordered = {};

      Object.keys(localCounter)
        .sort()
        .reduce((obj, key) => {
          ordered[key] = localCounter[key];
          return obj;
        }, {});

      const label = labelVisible && showEntry > 0
        ? JSON.stringify(Object.values(ordered).slice(0, level))
          .replace(/(\[|\])/g, '')
          .replaceAll(',', '.')
        : '';

      if (label.length > 0) {
        labels[refPathId] = label;
      }
    });

    return labels;
  }
};

// Helper functions

function buildModel(currentDocId, element) {
  const { elementRevisions, elements, similarities } = element;

  return ElementFactory.elementFromJSON(
    currentDocId,
    elements,
    elementRevisions[currentDocId],
    similarities
  );
}
