import { Schema } from 'prosemirror-model';
import marks from './marks';

import {
  section,
  tableNodes,
  paragraph,
  textContentPhrase,
  checkbox,
  textfield,
  textContentReference
} from './nodes/index';

const MainSchema = new Schema({
  marks,
  nodes: {
    section,
    table: tableNodes.table,
    table_header: tableNodes.table_header,
    table_row: tableNodes.table_row,
    table_cell: tableNodes.table_cell,
    paragraph,
    phrase: textContentPhrase,
    textfield,
    reference: textContentReference,
    checkbox,
    text: {
      group: 'inline'
    }
  },
  topNode: 'section'
});

export default MainSchema;
