import { component as MODULE } from '../store/state';

const ACTIONS = {
  FETCH_HASHTAGS_BY_ELEMENTTYPE: `${MODULE}fetch_hashtags_by_elementType`,
  FETCH_HASHTAG_TYPES: `${MODULE}fetch_hashtag_types`,
  FETCH_CHOICES: `${MODULE}fetch_choices`,
  FETCH_MUSTERVERTRAEGE: `${MODULE}fetch_mustervertraege`,
  FETCH_MUSTERDOKUMENTE: `${MODULE}fetch_musterdokumente`,
  FETCH_QUESTIONNAIRE: `${MODULE}fetch_questionnaire`,

  FETCH_HILFE_BY_ROUTE: `${MODULE}fetch_hilfe_by_route`,
  FETCH_HILFE_VIDEO_NAME: `${MODULE}fetch_hilfe_video_name`,

  ADD_HASHTAG: `${MODULE}add_hashtag`,
  ADD_CHOICE: `${MODULE}add_choice`,

  ADD_SUBELEMENT_TO_MUSTERVERTRAG: `${MODULE}add_subelement_to_mustervertrag`,
  DELETE_SUBELEMENT_IN_MUSTERVERTRAG: `${MODULE}delete_subelement_in_mustervertrag`,

  UPDATE_HASHTAG: `${MODULE}update_hashtag`,
  UPDATE_CHOICE: `${MODULE}update_choice`,

  DEPRECATE_HASHTAG: `${MODULE}deprecate_hashtag`,
  DEPRECATE_CHOICE: `${MODULE}deprecate_choice`,

  SHOW_SNACKBAR: `${MODULE}show_snackbar`
};

export default ACTIONS;
