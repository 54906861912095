function generateTextfield(readonly = false) {
  const inputElement = document.createElement('input');
  inputElement.setAttribute('type', 'text');
  inputElement.innerText = '_';

  if (readonly) { inputElement.disabled = readonly; }

  inputElement.style.backgroundColor = 'rgba(0,0,0,0.1)';

  return inputElement;
}

export {
  generateTextfield
};

export default {
  inline: true,
  group: 'inline',
  marks: '',
  attrs: {
    readonly: { default: true }
  },
  toDOM: (node) => generateTextfield(node.attrs.readonly),

  parseDOM: [
    {
      tag: 'input'
    }
  ]
};
