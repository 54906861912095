export default {
  inline: true,
  group: 'inline',
  marks: '',
  attrs: {
    description: { default: '' },
    type: { default: 'internal' },
    id: { default: null }
  },
  toDOM: () => {
    const element = document.createElement('reference');

    return element;
  },
  parseDOM: [
    {
      tag: 'input'
    }
  ]
};
