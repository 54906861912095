import { isJSON } from '@st/utils-js';
import Element from './Element';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';
import MustervertragElementAttrs from './MustervertragElementAttrs';
import SubelementContentElement from './SubelementContentElement';

export default class MustervertragElement extends Element {
  /**
   *
   * @param {String} id UUID
   * @param {String} state value of ElementStatus
   * @param {MustervertragElementAttrs} attrs
   * @param {Number} revision
   * @param {Array} content Array of SubelementContentElements
   */
  constructor(
    id = null,
    state = ElementStatus.MODIFIABLE,
    attrs = new MustervertragElementAttrs(),
    revision = 0,
    content = []
  ) {
    super(id, ElementTyp.MUSTERVERTRAG, state, attrs, revision, content);
  }

  /**
   * Creates MustervertragElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      state = ElementStatus.MODIFIABLE,
      revision
    } = value;

    let localAttrs = new MustervertragElementAttrs();
    if (isJSON(value.attrs)) {
      localAttrs = MustervertragElementAttrs.fromJSON(value.attrs);
    }

    let localContent = [];
    if (Array.isArray(value.content)) {
      localContent = value.content.map((item) => {
        if (isJSON(item)) {
          return SubelementContentElement.fromJSON(item);
        }

        return item;
      });
    }

    return new MustervertragElement(id, state, localAttrs, revision, localContent);
  }

  /**
   * @returns MustervertragElement in JSON representation
   */
  toJSON() {
    let attrs = null;
    if (this.attrs instanceof MustervertragElementAttrs) {
      attrs = this.attrs.toJSON();
    } else {
      attrs = this.attrs;
    }

    const content = this.content.map((item) => {
      if (item instanceof SubelementContentElement) {
        return item.toJSON();
      }
      return item;
    });

    return {
      id: this.id,
      state: this.state,
      type: this.type,
      attrs,
      revision: this.revision,
      content
    };
  }

  get validRevision() {
    return typeof this.revision === 'number' && this.revision >= 0;
  }

  get validState() {
    return !!this.state
      && typeof this.state === 'string'
      && ElementStatus.isValidState(this.state);
  }

  get validAttrs() {
    return !!this.attrs
      && this.attrs instanceof MustervertragElementAttrs
      && this.attrs.valid;
  }

  get validContent() {
    return !!this.content
      && Array.isArray(this.content)
      && this.content.length > 0
      && this.content.every((subelement) => {
        const isSubelementContentElement = subelement instanceof SubelementContentElement;

        return isSubelementContentElement && subelement.valid;
      });
  }

  get valid() {
    const { validId } = this;

    const valid = validId
      && this.validType
      && this.validState
      && this.validAttrs
      && this.validContent
      && this.validRevision;

    return valid;
  }
}
