import getUserPreferenceRoute from '../utils/getUserPreferenceRoute';
import store from '../store';
import types from '../store/types';

export default function hilfe(to, from, next) {
  const url = from.name ? to.path : window.location.href.split('#')[1];
  const urlWithoutQuery = url.split('?')[0];

  store.commit(types.mutations.viewContent.SET_HILFE_VIDEO_ID, null);

  if (urlWithoutQuery.includes('=')) {
    next();
    return;
  }

  const route = to.matched.at(0).path;
  const preparedURL = getUserPreferenceRoute(urlWithoutQuery, route);

  Promise.all([
    store
      .dispatch(
        types.actions.user.GET_PATH_PREFERENCE,
        preparedURL
      ).catch((error) => console.log(error)),
    store
      .dispatch(
        types.actions.viewContent.FETCH_HILFE_BY_ROUTE,
        preparedURL
      ).catch((error) => console.log(error))
  ])
    .then(() => next())
    .catch((e) => console.log(e));
}
