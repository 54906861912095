import types from '@src/store/types';
import { useKeycloakToken } from '../plugins/axios-keycloak';

const axios = useKeycloakToken();

/**
 *
 * @param {String} method request method
 * @param {String} url
 * @param {JSON} payload Object which will be send
 */
async function executeAxios(method, url, payload = undefined) {
  if (!method) throw new Error('Request must have a method');
  if (!url) throw new Error('Request must have an url');

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const response = await axios[method](`${apiUrl}${url}`, payload);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 *
 * @param {String} url
 */
function executeGet(url) { return executeAxios('get', url); }

/**
 *
 * @param {String} url
 * @param {JSON} payload
 */
function executePut(url, payload) { return executeAxios('put', url, payload); }

/**
 *
 * @param {String} url
 * @param {JSON} payload
 */
function executeDelete(url) { return executeAxios('delete', url); }

/**
 *
 * @param {String} url
 * @param {JSON} payload
 */
function executePost(url, payload) { return executeAxios('post', url, payload); }

/**
 *
 * @param {String} url
 * @param {JSON} payload
 */
function executePatch(url, payload) { return executeAxios('patch', url, payload); }

const viewContentMutations = types.mutations.viewContent;

function authWrapper({ commit }, promise) {
  return new Promise((resolve, reject) => {
    promise
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        if (error && error.response?.status === 403) {
          commit(
            `${viewContentMutations.SHOW_SNACKBAR_ERROR}`,
            'Sie sind nicht berechtigt diese Aktion auszuführen'
          );
          resolve();
          return;
        }
        reject(error);
      });
  });
}

export {
  executeGet as get,
  executePut as put,
  executePost as post,
  executePatch as patch,
  executeDelete as remove,
  authWrapper
};
