import { get } from '../axios';

const API_PREFIX = '/mandantenUsers';

export default {
  /**
   * get all users of all mandants
   *
   * @returns
   */
  getAllMandantenUsers() {
    const URL = `${API_PREFIX}/list`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
};
