export default {
  inline: true,
  group: 'inline',
  marks: '',
  attrs: {
    id: { default: null },
    content: { default: [] },
    comments: { default: [] },
    question: { default: '' },
    hashtags: { default: [] },
    name: { default: '' },
    choice: { default: '' },
    preFormulatedQuestion: { default: '' },
    externalExplanations: { default: '' },
    internalExplanations: { default: '' }
  },
  toDOM: () => {
    const phraseNode = document.createElement('phrase');

    return phraseNode;
  },
  parseDOM: [
    {
      tag: 'span',
      getAttrs: (dom) => ({ text: dom.innerText })
    }
  ]
};
