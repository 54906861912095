import { authWrapper } from '@src/services/axios';
import { isEmpty } from '@st/utils-js';
import services from '../../../../services';

import FreigabeChecklist from '../../../../model/FreigabeChecklist';
import Patcher from '../../../../utils/Patcher';
import sectionRefPath from '../../../../utils/sectionRefPath';
import viewContentMutations from '../../viewContent/types/mutation-types';
import ACTIONS from '../types/action-types';
import GETTERS from '../types/getter-types';
import MUTATIONS from '../types/mutation-types';

export default {

  [ACTIONS.GET_FREIGABE_STATUS]({ dispatch }, {
    clientElementId,
    elementId
  }) {
    if (clientElementId) {
      dispatch(ACTIONS.GET_MANDANT_FREIGABE_STATUS, { clientElementId });
    } else if (elementId) {
      dispatch(ACTIONS.GET_INTERNE_FREIGABE_STATUS, { elementId });
    }
  },
  [ACTIONS.GET_INTERNE_FREIGABE_STATUS]({ commit, dispatch }, {
    elementId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.workflow.aufgaben.getElementGetFreigabeAufgabe(elementId)
        .then((result) => {
          commit(MUTATIONS.SET_FREIGABE_IS_APPROVAL, result.data.freigabe);
          if (result.data.freigabe) {
            // TODO: fetch checklist;
            commit(MUTATIONS.SET_FREIGABE_TASKID, result.data.aufgabeBearbeiterId);
            dispatch(
              ACTIONS.FETCH_TASKPROGRESS,
              { taskId: result.data.aufgabeBearbeiterId }
            );
          }
          resolve(result);
        })
        .catch((error) => {
          if (error.response.status !== 405) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);
          }

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [ACTIONS.GET_MANDANT_FREIGABE_STATUS]({ commit }, {
    clientElementId
  }) {
    const prm = new Promise((resolve, reject) => {
      services.editor.client.getClientElementReleaseFlag(clientElementId)
        .then((result) => {
          commit(MUTATIONS.SET_FREIGABE_STATUS, result.data.freigabeStatus);
          resolve(result);
        })
        .catch((error) => {
          if (error.response.status !== 405) {
            commit(viewContentMutations.SHOW_SNACKBAR_ERROR, error.message);
          }

          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  },
  [ACTIONS.FETCH_TASKPROGRESS]({ commit }, {
    taskId
  }) {
    const prm = new Promise((resolve) => {
      services.workflow.freigabe.fetch(taskId)
        .then((result) => {
          if (result) {
            const checklist = result.data.content;
            const { revision } = result.data;
            commit(MUTATIONS.SET_CHECKLIST, { checklist, revision });
            resolve(result.data);
          } else {
            commit(MUTATIONS.SET_CHECKLIST, { checklist: {} });
            resolve({});
          }
        });
    });

    return authWrapper({ commit }, prm);
  },
  [ACTIONS.TASKPROGRESS_CHECK_SECTION]({ dispatch }, { section, revision, elementId }) {
    const key = sectionRefPath(section);
    dispatch(ACTIONS.TASKPROGRESS_CHECK, { key, revision, elementId });
  },
  [ACTIONS.TASKPROGRESS_CHECK]({
    dispatch, commit, getters, rootState
  }, { key, revision, elementId }) {
    const checklist = getters[GETTERS.FREIGABEPROZESS_CHECKLIST].toJSON();
    const checklistRevision = getters[GETTERS.FREIGABEPROZESS_CHECKLIST].revision;

    const copy = FreigabeChecklist.fromJSON(checklist, checklistRevision);
    copy.toggleChecked(key, revision);

    const { element } = rootState.editor;

    return dispatch(ACTIONS.UPDATE_TASKPROGRESS, {
      taskId: getters[GETTERS.FREIGABE_STATUS].taskId,
      elementId: elementId ?? element.editorElement,
      newChecklist: copy,
      revision: checklistRevision
    })
      .then(() => {
        commit(MUTATIONS.SET_CHECKLIST, { checklist: copy });
      })
      .catch(() => {
        commit(MUTATIONS.SHOW_SNACKBAR_ERROR, 'Ein Fehler ist aufgetreten');
      });
  },
  [ACTIONS.UPDATE_CHECKBOX_TASKPROGRESS]({ getters, commit, dispatch }, {
    taskId,
    elementId,
    oldElementRevision,
    newElementRevision,
    checkboxKey
  }) {
    let copyChecklist = {};
    if (!isEmpty(getters[GETTERS.FREIGABEPROZESS_CHECKLIST])) {
      const checklist = getters[GETTERS.FREIGABEPROZESS_CHECKLIST];
      copyChecklist = FreigabeChecklist
        .fromJSON(checklist.toJSON(), checklist.revision);
    } else {
      return Promise.resolve();
    }

    copyChecklist.updateRevisions([ checkboxKey ], oldElementRevision, newElementRevision);

    return dispatch(ACTIONS.UPDATE_TASKPROGRESS, {
      taskId,
      elementId,
      revision: copyChecklist.revision - 1,
      newChecklist: copyChecklist
    }).then(() => {
      commit(MUTATIONS.SET_CHECKLIST, { checklist: copyChecklist });
    })
      .catch(() => {
        commit(MUTATIONS.SHOW_SNACKBAR_ERROR, 'Ein Fehler ist aufgetreten');
      });
  },
  [ACTIONS.TOGGLE_CHECKBOX_TASKPROGRESS]({ getters, dispatch, rootState }, {
    elementId,
    revision = undefined,
    checkboxKey
  }) {
    const { taskId } = getters[GETTERS.FREIGABE_STATUS];
    const { element } = rootState.editor;
    const oldElementRevision = revision ?? element.elementRevisions[elementId];
    const newElementRevision = oldElementRevision + 1;

    return dispatch(ACTIONS.UPDATE_CHECKBOX_TASKPROGRESS, {
      taskId,
      oldElementRevision,
      newElementRevision,
      elementId,
      checkboxKey
    });
  },
  [ACTIONS.UPDATE_TASKPROGRESS]({ getters, commit }, {
    taskId,
    elementId,
    revision,
    newChecklist
  }) {
    let oldElement = {};
    if (!isEmpty(getters[GETTERS.FREIGABEPROZESS_CHECKLIST])) {
      oldElement = getters[GETTERS.FREIGABEPROZESS_CHECKLIST].toJSON();
    }
    const newElement = newChecklist.toJSON();
    const patches = Patcher.createPatches(
      oldElement,
      newElement
    );

    const prm = new Promise((resolve, reject) => {
      services.workflow.freigabe.update(taskId, elementId, revision, patches)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return authWrapper({ commit }, prm);
  }
};
