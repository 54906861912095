import { deepClone } from '@st/utils-js';
import moment from 'moment';
import Entscheidung from '../../../../model/Entscheidung';
import Hashtag from '../../../../model/Hashtag';
import ElementStatus from '../../../../model/enums/ElementStatus';
import types from '../types/mutation-types';

export default {
  [types.CLEAR_HASHTAGS](state) {
    state.hashtags = [];
  },
  [types.CLEAR_HASHTAG_TYPES](state) {
    state.hashtagTypes = {};
  },
  [types.CLEAR_CHOICES](state) {
    state.choices = [];
  },

  [types.SET_HASHTAGS](state, { hashtags }) {
    state.hashtags = hashtags?.slice();
  },
  [types.SET_HASHTAG_TYPES](state, { hashtagTypes }) {
    state.hashtagTypes = { ...hashtagTypes };
  },
  [types.SET_CHOICES](state, { choices }) {
    state.choices = choices?.slice();
  },
  [types.SET_MUSTERVERTRAEGE](state, { mustervertraege }) {
    state.mustervertraege = mustervertraege?.slice();
  },
  [types.SET_MUSTERVERTRAG_STATE_TO_APPROVED](state, { editorElementId, currentUser }) {
    const mustervertrag = state.mustervertraege.find(
      (entry) => entry.elementId === editorElementId
    );
    if (mustervertrag) {
      mustervertrag.state = ElementStatus.APPROVED;
      mustervertrag.revision += 1;
      mustervertrag.latestPatchDetails = [
        {
          ...currentUser,
          created: new Date().getUTCMilliseconds
        },
        ...mustervertrag.latestPatchDetails
      ];
      mustervertrag.subElements = mustervertrag.subElements.map((subElement) => ({
        ...subElement,
        state: ElementStatus.APPROVED
      }));
    }
  },

  [types.SET_HILFE_VIDEO_ID](state, id) {
    state.hilfe.id = id;
  },
  [types.SET_HILFE_VIDEO_NAME](state, videoName) {
    state.hilfe.videoName = videoName;
  },

  [types.UPDATE_HASHTAG](state, { hashtag: hashtagObj }) {
    let updateHashtag = state.hashtags.find(
      (hashtag) => hashtag.id === hashtagObj.id
    );
    const indexOfHashtag = state.hashtags.indexOf(updateHashtag);
    updateHashtag = { ...updateHashtag, ...hashtagObj };
    state.hashtags.splice(indexOfHashtag, 1, updateHashtag);
  },
  [types.UPDATE_HASHTAG_TYPE](state, { hashtagTypeId, hashTagType }) {
    state.hashtagTypes[hashtagTypeId] = hashTagType;
  },
  [types.UPDATE_CHOICE](state, { choice: choiceObj }) {
    let updateChoice = state.choices.find(
      (choice) => choice.id === choiceObj.id
    );
    const indexOfChoice = state.choices.indexOf(updateChoice);
    updateChoice = { ...updateChoice, ...choiceObj };
    state.choices.splice(indexOfChoice, 1, updateChoice);
  },
  [types.UPDATE_MUSTERVERTRAG](state, { contract }) {
    const index = state.mustervertraege
      .findIndex((mustervertrag) => mustervertrag.elementId === contract.elementId);
    state.mustervertraege.splice(index, 1, contract);
  },

  [types.REPLACE_SUB_ELEMENT_WITH_DESCENDANT](state, {
    parentElementId,
    subElementRefPathId,
    newElement,
    activeUser
  }) {
    const parentElementIndex = state.mustervertraege.findIndex(
      (element) => element.elementId === parentElementId
    );
    const parentElement = deepClone(state.mustervertraege[parentElementIndex]);
    parentElement.subElements = parentElement.subElements
      .map((subElement) => {
        if (subElement.refPathId === subElementRefPathId) {
          return {
            ...subElement,
            containedElementId: newElement.subElementId,
            name: newElement.name
          };
        }
        return subElement;
      });
    parentElement.modified = moment().valueOf();

    parentElement.latestPatchDetails = [
      {
        ...parentElement.latestPatchDetails[0],
        keycloakUserUuid: activeUser.keycloakUserUuid,
        firstname: activeUser.firstname,
        lastname: activeUser.lastname,
        email: activeUser.email,
        created: moment().valueOf()
      }
    ];

    parentElement.revision += 1;

    state.mustervertraege.splice(parentElementIndex, 1, parentElement);
  },

  [types.INCREASE_RESET_FRAGEBOGEN_COUNTER](state) {
    state.resetFragebogenCounter += 1;
  },

  [types.INCREASE_REVISION_OF_MUSTERVERTRAG](state, { elementId }) {
    const index = state.mustervertraege
      .findIndex((mustervertrag) => mustervertrag.elementId === elementId);
    state.mustervertraege[index].revision += 1;
  },

  [types.ADD_HASHTAG](state, { hashtag }) {
    const mappedHashtag = hashtag instanceof Hashtag
      ? hashtag
      : Hashtag.fromJSON(hashtag);
    state.hashtags.push(mappedHashtag);
  },
  [types.ADD_CHOICE](state, { choice }) {
    const mappedChoice = choice instanceof Entscheidung
      ? choice
      : Entscheidung.fromJSON(choice);
    state.choices.push(mappedChoice);
  },
  [types.ADD_MUSTERVERTRAG](state, { contract }) {
    state.mustervertraege.unshift({ ...contract });
  },
  [types.ADD_SUBELEMENT_TO_MUSTERVERTRAG](state, {
    vertragId,
    subelement,
    refPathId
  }) {
    const currentMustervertrag = state.mustervertraege.find(
      (mustervertrag) => mustervertrag.elementId === vertragId
    );

    const newSubElement = {
      containedElementId: subelement.elementId,
      name: subelement.name,
      type: subelement.type,
      state: subelement.state,
      refPathId
    };

    currentMustervertrag.subElements.push(newSubElement);
  },

  [types.REMOVE_SUBELEMENT_IN_MUSTERVERTRAG](state, {
    mustervertragElementId,
    refPathId
  }) {
    if (!state.mustervertraege || state.mustervertraege.length < 1) return;

    const currentMustervertrag = state.mustervertraege.find(
      (mustervertrag) => mustervertrag.elementId === mustervertragElementId
    );

    const indexOfMusterdokument = currentMustervertrag.subElements.findIndex(
      (musterdokument) => musterdokument.refPathId === refPathId
    );

    currentMustervertrag.subElements.splice(indexOfMusterdokument, 1);
  },

  [types.REMOVE_MUSTERVERTRAG](state, { mustervertragElementId }) {
    const indexOfMustervertrag = state.mustervertraege.findIndex(
      (mustervertrag) => mustervertrag.elementId === mustervertragElementId
    );

    state.mustervertraege.splice(indexOfMustervertrag, 1);
  },

  [types.DEPRECATE_HASHTAG](state, { hashtag: hashtagObj }) {
    let updateHashtag = state.hashtags.find(
      (hashtag) => hashtag.id === hashtagObj.id
    );
    const indexOfHashtag = state.hashtags.indexOf(updateHashtag);
    updateHashtag = {
      ...updateHashtag,
      name: `${hashtagObj.id}_${updateHashtag.name}`,
      state: 'deprecated'
    };
    state.hashtags.splice(indexOfHashtag, 1, updateHashtag);
  },
  [types.DEPRECATE_CHOICE](state, { choice: choiceObj }) {
    let updateChoice = state.choices.find(
      (choice) => choice.id === choiceObj.id
    );
    const indexOfChoice = state.choices.indexOf(updateChoice);
    updateChoice = {
      ...updateChoice,
      state: 'deprecated'
    };
    state.choices.splice(indexOfChoice, 1, updateChoice);
  },

  [types.SHOW_SNACKBAR](state, { text, type, timeout }) {
    state.snackbar = { text, type, timeout };
  },
  [types.SHOW_SNACKBAR_ERROR](state, text) {
    state.snackbar = { text, type: 'error', timeout: '3000' };
  },
  [types.SHOW_SNACKBAR_SUCCESS](state, text) {
    state.snackbar = { text, type: 'success', timeout: '3000' };
  },
  [types.SHOW_SNACKBAR_WARNING](state, text) {
    state.snackbar = { text, type: 'warning', timeout: '3000' };
  },
  [types.SHOW_SNACKBAR_INFO](state, text) {
    state.snackbar = { text, type: 'info', timeout: '3000' };
  }
};
