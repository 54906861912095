import axios from 'axios';

const LOCAL_STORAGE_TOKEN_NAME = 'vue-token';

function setKeycloakToken(token) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, token);
}

function getKeycloakToken() {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
}

function useKeycloakToken(token = getKeycloakToken()) {
  Object.assign(axios.defaults.headers, {
    Authorization: `Bearer ${token}`
  });
  return axios;
}

export {
  setKeycloakToken,
  getKeycloakToken,
  useKeycloakToken
};
