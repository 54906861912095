<template>
  <v-app>
    <template v-if="initialized">
      <banner />
      <bbh-header
        :header-menu-icons="headerMenuIcons"
        @click="handleBtnClick($event)"
        @click:logo="routeToHome"
      >
        <template #menu>
          <bbhMenu />
        </template>

        <template #servicezugang>
          <ServicezugangAuswahl />
        </template>

        <bbh-header-menu>
          <div>
            <bbh-search-dialog />
          </div>
          <!-- TODO: FutureFeature
            <div class="header-btn-wrapper">
            <bbh-dialog-alerts :messages="alertMessages" />
          </div> -->
          <div class="header-btn-wrapper">
            <bbh-dialog-user
              :user="user"
              :client-name="clientName"
              @logout="logoutUser()"
            />
          </div>
          <!-- TODO: FutureFeature
            <template v-for="(icon, index) in headerMenuIcons">
            <v-badge
              :key="index"
              :content="icon.messages"
              :value="icon.messages? icon.message: 0"
              color="var(--bbh-red)"
              overlap
            >
              <v-btn
                :key="index"
                icon
                :style="`width: ${icon.width}; height: ${icon.height}`"
                :ripple="false"
                class="header-menu-btn"
                @click="handleBtnClick(icon.name)"
              >
                <v-icon
                  :style="`width: ${icon.width}; height: ${icon.height};
                  --bbh-icon-color: var(--bbh-black)`"
                  v-text="icon.name"
                />
              </v-btn>
            </v-badge>
          </template> -->
          <div class="header-btn-wrapper">
            <HilfeDialog
              @click="handelHilfeDialogClick($event)"
            />
          </div>
        </bbh-header-menu>
      </bbh-header>
      <SubHeader />
      <v-main>
        <router-view
          :key="currentClientId"
        />
      </v-main>
      <snackbar />
      <floating-button />
    </template>
    <HilfeVideoDialog
      :open-modal="helpDialogIsOpen"
      :dialog="hilfeDialogConfig"
      @close="closeHelpDialog()"
      @click:action="handleHelpAction($event)"
    />
    <SentryOverflow />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SentryOverflow from '@src/components/alerts/SentryOverflow.vue';
import Snackbar from './components/alerts/Snackbar.vue';
import Banner from './components/alerts/Banner.vue';
import BbhMenu from './components/sidemodals/BbhMenu.vue';
import types from './store/types';
import FloatingButton from './components/buttons/FloatingButton.vue';
import SubHeader from './components/individual/SubHeader.vue';
import ServicezugangAuswahl from './components/individual/ServicezugangAuswahl.vue';
import HilfeDialog from './components/dialogs/HilfeDialog.vue';
import HilfeVideoDialog from './components/dialogs/HilfeVideoDialog.vue';
import BbhSearchDialog from './components/individual/BbhSearchDialog.vue';
import getUserPreferenceRoute from './utils/getUserPreferenceRoute';

const userActions = types.actions.user;
const userGetters = types.getters.user;
const viewContentGetters = types.getters.viewContent;

export default {
  name: 'App',
  components: {
    snackbar: Snackbar,
    banner: Banner,
    bbhMenu: BbhMenu,
    SubHeader,
    HilfeDialog,
    HilfeVideoDialog,
    BbhSearchDialog,
    'floating-button': FloatingButton,
    SentryOverflow,
    ServicezugangAuswahl
  },
  data: () => ({
    headerMenuIcons: [],
    helpDialogIsOpen: false,
    hilfeDialogConfig: {
      header: 'Hilfe',
      buttons: [ { value: 'close', text: 'Schließen', outlined: true } ],
      small: false
    },

    hilfeVideoDialogWurdeAngegucktUndWeggeklickt: {},

    initialized: false
  }),
  computed: {
    ...mapGetters({
      user: userGetters.USER,
      currentClient: userGetters.CURRENT_CLIENT,
      hilfeVideo: viewContentGetters.HILFE_VIDEO
    }),

    clientName() {
      if (this.currentClient?.metadata?.isBbhClient) return 'Becker Büttner Held';

      return this.currentClient?.name;
    },

    currentClientId() {
      return this.currentClient?.clientId || '';
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: 'handleRouteUpdate'
    },
    'hilfeVideo.id': {
      handler: 'handleRouteUpdate'
    },
    'user.settings': {
      deep: true,
      handler: 'handleRouteUpdate'
    }
  },
  mounted() {
    this.fetchRequiredData();
  },
  methods: {
    ...mapActions({
      fetchUserClients: userActions.FETCH_USER_CLIENTS
    }),
    handelHilfeDialogClick(button) {
      switch (button.text) {
      case 'Video':
        this.openHelpDialog();
        break;
      default:
        break;
      }
    },
    handleRouteUpdate() {
      // eslint-disable-next-line no-undef
      const isDevEnv = process.env.NODE_ENV === 'development';

      if (isDevEnv || this.$route.matched.length === 0) return;

      const preparedURL = this.getUserPreferenceRoute();
      if (this.hilfeVideoDialogWurdeAngegucktUndWeggeklickt[preparedURL]) return;

      const currentUserPreference = this.user.settings[preparedURL];

      const currentHilfeVideoId = this.hilfeVideo.id;

      if (currentUserPreference === undefined || currentUserPreference === true) return;

      this.$nextTick(() => {
        this.helpDialogIsOpen = !!currentHilfeVideoId && !currentUserPreference;
      });
    },
    async fetchRequiredData() {
      this.setUserClients().then(() => {
        this.initialized = true;
      }).catch((error) => console.log(error));
    },
    async setUserClients() {
      return this.fetchUserClients();
    },

    getUserPreferenceRoute() {
      const route = this.$route.matched.at(0).path;
      const url = window.location.href.split('#')[1];
      const urlWithoutQuery = url?.split('?')[0];

      return getUserPreferenceRoute(urlWithoutQuery, route);
    },

    closeHelpDialog() {
      const preparedURL = this.getUserPreferenceRoute();
      this.hilfeVideoDialogWurdeAngegucktUndWeggeklickt[preparedURL] = true;

      this.helpDialogIsOpen = false;
    },

    openHelpDialog() {
      this.helpDialogIsOpen = true;
    },

    handleHelpAction(btn) {
      switch (btn.value) {
      case 'close':
        this.closeHelpDialog();
        break;
      default:
        break;
      }
    },

    openSearch() {
      this.$refs['search-dialog'].handleClick();
    },

    logoutUser() {
      this.$keycloak.logoutFn();
    },

    routeToHome() {
      this.$router.push({ name: 'Home' }).catch(() => {});
    },

    handleBtnClick(value) {
      switch (value) {
      case '$bbhLogo':
        this.$router.push({ name: 'Home' }).catch(() => {});
        break;
      case '$menuIcon':
        this.showModal = true;
        break;
      default:
        break;
      }
    }

  }
};
</script>

<style lang="scss">
.justify-self-center {
  justify-self: center !important;
}

.ladebox {
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;
}

#zitat-einstein {
  font-style: italic;
}

.scroll-wrapper {
  height: 100%;
  overflow: hidden;
}
</style>
