function open(url, snackBarErrorFnc, tabName = '_blank') {
  try {
    window.open(url, tabName).focus();
  } catch (error) {
    snackBarErrorFnc('Fenster konnte nicht geöffnet werden. Gegebenenfalls ist ein Popup-Blocker aktiv');
  }
}

function openWithContent(url, snackBarErrorFnc, content, tabName = '_blank') {
  try {
    const child = window.open(url, tabName);
    child.document.write(content);
    child.document.close();
    child.focus();
  } catch (error) {
    snackBarErrorFnc('Fenster konnte nicht geöffnet werden. Gegebenenfalls ist ein Popup-Blocker aktiv');
  }
}

export default {
  open,
  openWithContent
};
