import AenderungsmodusSectionState from '../enums/AenderungsmodusSectionState';

export default class AenderungsmodusPatchBody {
  /**
   * @param {Patch[]} patch
   * @param {AenderungsmodusSectionState} state
   * @param {String[]} order
   */
  constructor(
    patch,
    state,
    order
  ) {
    this.patch = patch;
    this.state = state;
    this.order = order;
    if (!AenderungsmodusSectionState.isValidState(state)) {
      throw new Error('Invalid state');
    }
  }

  toJSON() {
    return {
      patch: this.patch,
      state: this.state,
      order: this.order
    };
  }

  get validPatch() {
    return !!this.patch && Array.isArray(this.patch);
  }

  get validState() {
    return !!this.state && typeof this.state === 'string'
      && AenderungsmodusSectionState.isValidState(this.state);
  }

  get validOrder() {
    return !!this.order && Array.isArray(this.order)
      && this.order.every((element) => typeof element === 'string');
  }

  get valid() {
    return this.validPatch
      && this.validState
      && this.validOrder;
  }
}
