import { component as MODULE } from '../store/state';

const MUTATIONS = {
  SET_USER: `${MODULE}set_user`,
  SET_USER_CLIENTS: `${MODULE}set_user_clients`,
  SET_CLIENT_USERS: `${MODULE}set_client_users`,
  SET_CLIENT_TO_POSITION_ZERO: `${MODULE}set_client_to_position_zero`,

  SET_USER_PREFERENCE: `${MODULE}set_user_preference`,

  REMOVE_USER_ROLE: `${MODULE}remove_user_role`,
  REMOVE_USER_FROM_CLIENT_USERS: `${MODULE}remove_user_from_client_users`,
  ADD_USER_ROLE: `${MODULE}add_user_role`,

  UPDATE_USER: `${MODULE}update_user`,
  UPDATE_CLIENT: `${MODULE}update_client`,
  UPDATE_CLIENT_USER: `${MODULE}update_client_user`,

  SET_USER_TAGS: `${MODULE}set_user_tags`,
  ADD_OR_UPDATE_USER_TAG: `${MODULE}add_or_update_user_tag`,
  DELETE_USER_TAG: `${MODULE}delete_user_tag`
};

export default MUTATIONS;
