import { validate } from '@src/utils/uuid';

export default class AenderungsmodusComment {
  /**
   *
   * @param {String} keycloakUserUuid
   * @param {String} comment
   * @param {Number} created
   * @param {String} firstname
   * @param {String} lastname
   */
  constructor(
    keycloakUserUuid,
    comment,
    created,
    firstname = '',
    lastname = ''
  ) {
    this.keycloakUserUuid = keycloakUserUuid;
    this.firstname = firstname;
    this.comment = comment;
    this.created = created;
    this.lastname = lastname;
  }

  get validKeycloakUserUuid() {
    return !!this.keycloakUserUuid && typeof this.keycloakUserUuid === 'string'
      && validate(this.keycloakUserUuid);
  }

  get validFirstname() {
    return typeof this.firstname === 'string';
  }

  get validLastname() {
    return typeof this.lastname === 'string';
  }

  get validComment() {
    return !!this.comment && typeof this.comment === 'string';
  }

  get validCreated() {
    return !!this.created && typeof this.created === 'number';
  }

  get valid() {
    return this.validKeycloakUserUuid
      && this.validFirstname
      && this.validLastname
      && this.validComment
      && this.validCreated;
  }
}
