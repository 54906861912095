import { isJSON } from '@st/utils-js';
import { uuid, validate } from '../utils/uuid';
import ContentElement from './ContentElement';
import ElementTyp from './enums/ElementTyp';

export default class SubelementContentElement extends ContentElement {
  /**
   *
   * @param {String} id UUID
   * @param {String} choice UUID
   * @param {String} refPathId UUID
   * @param {String} externalExplanations
   * @param {String} internalExplanations
   * @param {String} preFormulatedQuestion
   */
  constructor(
    id = null,
    choice = '',
    refPathId = null,
    externalExplanations = undefined,
    internalExplanations = undefined,
    preFormulatedQuestion = undefined
  ) {
    super(id, undefined, ElementTyp.SUBELEMENT, choice, refPathId);
    this.externalExplanations = externalExplanations;
    this.internalExplanations = internalExplanations;
    this.preFormulatedQuestion = preFormulatedQuestion;
  }

  /**
   * Creates SubelementContentElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (value instanceof SubelementContentElement) return value;
    if (!isJSON(value)) return null;

    const {
      id = null,
      choice = '',
      refPathId = null,
      externalExplanations = undefined,
      internalExplanations = undefined,
      preFormulatedQuestion = undefined
    } = value;

    let localRefPathId = refPathId;
    if (!validate(localRefPathId)) {
      localRefPathId = uuid();
    }

    return new SubelementContentElement(
      id,
      choice,
      localRefPathId,
      externalExplanations,
      internalExplanations,
      preFormulatedQuestion
    );
  }

  static fromKlauselJSON(klausel) {
    const {
      id
    } = klausel;

    const klauselJSON = {
      id
    };

    return SubelementContentElement.createValidJSON(klauselJSON);
  }

  static createValidJSON(value) {
    return SubelementContentElement.fromJSON(value).toJSON();
  }

  /**
   * @returns SubelementContentElement in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      type: this.type,
      choice: this.choice || '',
      refPathId: this.refPathId,
      externalExplanations: this.externalExplanations || '',
      internalExplanations: this.internalExplanations || '',
      preFormulatedQuestion: this.preFormulatedQuestion || ''
    };
  }

  get validType() {
    return this.type && ElementTyp.isSubelement(this.type);
  }

  get valid() {
    const {
      validId, validState, validChoice, validRefPathId
    } = this;

    const valid = validId
      && validState
      && validChoice
      && validRefPathId
      && this.validType;

    return valid;
  }
}
