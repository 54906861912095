import { isJSON } from '@st/utils-js';
import PersistObj from './PersistObj';
import EntscheidungStatus from './enums/EntscheidungStatus';

export default class Entscheidung extends PersistObj {
  /**
   *
   * @param {String} id if null it will be generated
   * @param {String} name
   * @param {String} comment
   * @param {String} preFormulatedQuestion
   * @param {String} internalComment optional
   * @param {String} externalComment optional
   * @param {String} state value of EntscheidungStatus
   */
  constructor(
    id = null,
    name = '',
    comment = '',
    state = EntscheidungStatus.STANDARD,
    preFormulatedQuestion = '',
    internalComment = '',
    externalComment = ''
  ) {
    super(id);

    this.name = name;
    this.comment = comment;
    this.state = state;
    this.preFormulatedQuestion = preFormulatedQuestion;
    this.internalComment = internalComment;
    this.externalComment = externalComment;
  }

  /**
   * Creates Entscheidung from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null,
      name = '',
      comment = '',
      state = EntscheidungStatus.STANDARD,
      preFormulatedQuestion = '',
      internalComment = '',
      externalComment = ''
    } = value;

    return new Entscheidung(
      id,
      name,
      comment,
      state,
      preFormulatedQuestion,
      internalComment,
      externalComment
    );
  }

  /**
   * @returns Entscheidung in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      comment: this.comment,
      state: this.state,
      preFormulatedQuestion: this.preFormulatedQuestion,
      internalComment: this.internalComment,
      externalComment: this.externalComment
    };
  }

  get valid() {
    const { validId } = this;
    const validName = typeof this.name === 'string' && !!this.name.trim();
    const validComment = typeof this.comment === 'string';
    const validPreFormulatedQuestion = typeof this.preFormulatedQuestion === 'string';
    const validInternalComment = typeof this.internalComment === 'string';
    const validExternalComment = typeof this.externalComment === 'string';
    const validState = !!this.state
      && typeof this.state === 'string'
      && EntscheidungStatus.isValidState(this.state);

    const valid = validId
    && validComment
    && validName
    && validState
    && validPreFormulatedQuestion
    && validInternalComment
    && validExternalComment;
    return valid;
  }
}
