export default {
  subHeaderLoading: false,

  subHeaderActionBar: [],
  subHeaderActionBarComponent: '',

  newMusterelement: false,

  migration: false,

  individuellerVertrag: false,

  editContract: 0,

  fragebogenBearbeiten: {
    type: '',
    value: ''
  },
  fragebogen: {
    type: '',
    value: ''
  },

  servicezugangDialog: false,

  kollektionen: '',
  kollektionenBearbeiten: '',

  paketverwaltung: ''
};

export const component = 'subHeader/';
