export default class AenderungsmodusSectionState {
  static NEW = 'new';

  static EXISTING = 'existing';

  static NEW_DELETED = 'new_deleted';

  static EXISTING_DELETED = 'existing_deleted';

  static isValidState(state) {
    const states = [
      this.NEW, this.EXISTING, this.NEW_DELETED, this.EXISTING_DELETED
    ];
    return states.includes(state);
  }

  static isNew(state) {
    return state === this.NEW;
  }

  static isExisting(state) {
    return state === this.EXISTING;
  }

  static isNewDeleted(state) {
    return state === this.NEW_DELETED;
  }

  static isExistingDeleted(state) {
    return state === this.EXISTING_DELETED;
  }

  static isDeleted(state) {
    return this.isNewDeleted(state) || this.isExistingDeleted(state);
  }
}
