export default class CompareMode {
  static TEXT = 'text';

  static ORIGINAL = 'original';

  static isType(type) {
    const types = [
      this.TEXT, this.ORIGINAL
    ];
    return types.includes(type);
  }

  static isText(type) {
    return type === this.TEXT;
  }

  static isOriginal(type) {
    return type === this.ORIGINAL;
  }
}
