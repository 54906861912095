import { isJSON } from '@st/utils-js';
import PersistObj from './PersistObj';

export default class HashtagTyp extends PersistObj {
  /**
   *
   * @param {String} id UUID
   * @param {String} name
   * @param {Number} nr
   * @param {Boolean} isRequired
   */
  constructor(id = null, name = '', nr = 0, isRequired = 0) {
    super(id);
    this.name = name;
    this.nr = nr;
    this.isRequired = isRequired;
  }

  /**
   * Creates HashtagTyp from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      id = null, name = '', nr = 0, isRequired = 0
    } = value;
    return new HashtagTyp(id, name, nr, isRequired);
  }

  /**
   * @returns HashtagTyp in JSON representation
   */
  toJSON() {
    return {
      id: this.id,
      name: this.name,
      nr: this.nr,
      isRequired: this.isRequired
    };
  }

  get valid() {
    const { validId } = this;
    const validName = !!this.name && typeof this.name === 'string' && !!this.name.trim();
    const validNr = typeof this.nr === 'number' && this.nr >= 0;
    const validIsRequired = typeof this.isRequired === 'number'
      && (this.isRequired === 0 || this.isRequired === 1);

    return validId && validName && validNr && validIsRequired;
  }
}
