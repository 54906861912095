import ElementTyp from '@src/model/enums/ElementTyp';
import { isEmptyOrNullish } from '@st/utils-js';
import {
  get
} from '../axios';
import editorClientService from '../editor/client';
import config from './config';

const { API_PREFIX } = config;

export default {
  /**
   * Get zwangsupdate by clientElementId
   *
   * @param {String} clientElementId
   */
  getZwangsupdate(clientElementId) {
    const URL = `${API_PREFIX}/clientElement/${clientElementId}/zwangsupdate`;

    return get(URL);
  },

  /**
   * Get updated sub elements for client element
   *
   * @param {String} clientElementId
   * @param {Array} subElements
   */
  async getUpdatedSubElementsForClientElement(clientElementId, subElements) {
    try {
      const response = await editorClientService.getClientElementUpdates(clientElementId);

      const { updates } = response.data;

      if (isEmptyOrNullish(updates)) return Promise.resolve([]);

      const updatedSectionIds = Object.keys(updates.clientElementUpdates);

      const sectionSubElementMap = subElements.reduce((acc, subElement) => {
        let contentKeys = [];
        if (ElementTyp.isBaustein(subElement.type)) {
          contentKeys.push(subElement.id);
        } else {
          contentKeys = Object.keys(subElement.content?.contents ?? {});
        }

        contentKeys.forEach((contentKey) => {
          acc[contentKey] = subElement.id;
        });

        return acc;
      }, {});

      const updateDocIdList = updatedSectionIds
        .reduce((acc, sectionId) => {
          const sectionSubElementId = sectionSubElementMap[sectionId];
          if (!!sectionSubElementId && !acc.includes(sectionSubElementId)) {
            acc.push(sectionSubElementId);
          }
          return acc;
        }, []);

      return Promise.resolve(updateDocIdList);
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
