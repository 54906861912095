<template>
  <div>
    <v-btn
      id="menu-button"
      icon
      :ripple="false"
      plain
      @click="showModal = true"
    >
      <v-icon
        id="sh-2"
        :ripple="false"
      >
        $menuIcon
      </v-icon>
    </v-btn>
    <bbh-modal
      size="default"
      side="left"
      :trigger-modal="showModal"
      :first-modal="true"
      :fixed="true"
      @close:modal="handleCloseModal()"
    >
      <template
        v-for="list in lists"
      >
        <bbh-list
          v-if="!list.userHasMinOfRolesArray || userHasMinOneOfRoles(list.userHasMinOfRolesArray)"
          :key="list.id"
          :items="list.items"
          :list-name="list.name"
          @click:item="closeModal($event)"
        />
      </template>
    </bbh-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserRolle from '../../model/enums/UserRolle';
import types from '../../store/types';

const userGetters = types.getters.user;

export default {
  name: 'BbhMenu',
  data: () => ({
    showModal: false

  }),
  computed: {
    ...mapGetters({
      userHasMinOneOfRoles: userGetters.USER_HAS_MIN_ONE_OF_ROLES
    }),
    lists() {
      return [
        {
          id: 0,
          name: 'Vertragsgenerator',
          items: [
            {
              text: 'BBH-Muster individualisieren',
              icon: '$businessContractApprove',
              to: 'Kollektionen'
            },
            {
              text: 'Meine Dokumente',
              icon: '$officeShelf1',
              to: 'MeineDokumente',
              params: {
                openNewContractDialog: false
              }
            },
            {
              text: 'Meine Rechnungen',
              icon: '$shoppingReceipts',
              to: 'MeineRechnungen'
            },
            {
              text: 'Meine Abos',
              icon: '$moneyFinances',
              to: 'MeineAbos'
            }
          ]
        },
        {
          id: 2,
          name: 'Administration',
          userHasMinOfRolesArray: [
            UserRolle.PARTNER,
            UserRolle.ADMINPARTNER,
            UserRolle.PRODUKTKOORDINATOR,
            UserRolle.MANDANTENFREIGABE,
            UserRolle.PRODUKTFREIGABE
          ],
          items: this.adminListItems
        }
      ];
    },
    adminListItems() {
      const allRoles = [
        UserRolle.PARTNER,
        UserRolle.ADMINPARTNER,
        UserRolle.PRODUKTKOORDINATOR,
        UserRolle.MANDANTENFREIGABE,
        UserRolle.PRODUKTFREIGABE
      ];
      return [
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$hash',
            text: 'Hashtags',
            to: 'Hashtags'
          }
          : undefined,
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$officeSignDocument',
            text: 'Entscheidungen',
            to: 'Entscheidungen'
          }
          : undefined,
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$customCards',
            text: 'Kollektionen bearbeiten',
            to: 'KollektionenBearbeiten'
          }
          : undefined,
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$folderText',
            text: 'Musterverträge',
            to: 'Mustervertraege'
          }
          : undefined,
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$officeFileText',
            text: 'Musterdokumente',
            to: 'Musterdokumente'
          }
          : undefined,
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$officeFileKlausel',
            text: 'Musterklauseln',
            to: 'Musterklauseln'
          }
          : undefined,
        this.userHasMinOneOfRoles(allRoles) ? {
          icon: '$taskListEdit1',
          text: 'Taskansicht',
          to: 'Taskansicht'
        }
          : undefined,
        this.userHasMinOneOfRoles(allRoles) ? {
          icon: '$cashSearch',
          text: 'Rechnungen',
          to: 'Rechnungen'
        }
          : undefined,
        this.userHasMinOneOfRoles([ UserRolle.PRODUKTFREIGABE ]) ? {
          icon: '$boxes',
          text: 'Paketverwaltung',
          to: 'Paketverwaltung'
        }
          : undefined,
        this.userHasMinOneOfRoles([ UserRolle.PRODUKTFREIGABE ]) ? {
          icon: '$userBox',
          text: 'Mandanten-Paketverwaltung',
          to: 'MandantenPaketverwaltung'
        }
          : undefined,
        this.userHasMinOneOfRoles([
          UserRolle.PARTNER,
          UserRolle.ADMINPARTNER,
          UserRolle.PRODUKTKOORDINATOR
        ]) ? {
            icon: '$cogHandGive1',
            text: 'Servicezugänge',
            to: 'Servicezugangverwaltung'
          }
          : undefined,
        this.userHasMinOneOfRoles(allRoles) ? {
          icon: '$boxes',
          text: 'Liste aller Nutzer der Mandanten',
          to: 'MandantenUsersList'
        }
          : undefined
      ].filter((v) => !!v);
    }
  },
  methods: {
    handleCloseModal() {
      this.showModal = false;
    },

    closeModal(event) {
      if (event.item.to) {
        this.handleCloseModal();
      }
    }
  }
};
</script>

<style lang='scss'>
#menu-button {
  height: 26px;
  width: 26px;
  margin-left: 20px
}

#menu-button.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 1;
}
</style>
