import {
  post
} from '../axios';

const REQUEST_MAPPING = 'docx';

export default {
  /** Simulate migration to validate payload
   *
   * @param {Object} payload - payload to validate
   */
  simulateOnly(payload) {
    const url = `/${REQUEST_MAPPING}/migrationUpload/simulateOnly`;
    return new Promise((resolve, reject) => {
      post(url, payload)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  },

  /** Migrate docx file to database
   *
   * @param {Object} payload - payload to migrate
  */
  migrate(payload) {
    const url = `/${REQUEST_MAPPING}/migrationUpload`;
    return new Promise((resolve, reject) => {
      post(url, payload)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }
};
