import Vue from 'vue';
import Vuex from 'vuex';

import UserModule from './modules/user/store';
import ViewContentModule from './modules/viewContent/store';
import SubHeaderModule from './modules/subHeader/store';
import EditorModule from './modules/editor/store';
import MandantEditorModule from './modules/mandantEditor/store';
import SharedEditorModule from './modules/sharedEditor/store';
import AufgabenModule from './modules/aufgaben/store';
import MandantenModule from './modules/mandanten/store';
import ShopAdminModule from './modules/shopAdmin/store';
import ShopClientModule from './modules/shopClient/store';
import ShopInfoModule from './modules/shopInfo/store';

/* istanbul ignore next */
Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true,
  apiKey: '',
  modules: {
    user: UserModule,
    viewContent: ViewContentModule,
    subHeader: SubHeaderModule,
    editor: EditorModule,
    mandantEditor: MandantEditorModule,
    sharedEditor: SharedEditorModule,
    aufgaben: AufgabenModule,
    mandanten: MandantenModule,
    shopAdmin: ShopAdminModule,
    shopClient: ShopClientModule,
    shopInfo: ShopInfoModule
  }
});

export default store;

const useStore = () => store;

export {
  useStore
};
