<template>
  <div
    id="snackbar-wrapper"
    name="snackbars tw-h-0 tw-relative"
  >
    <bbh-snackbar-queue
      class="tw-z-10"
      :snackbars="localSnackbars"
      @remove:snackbar="removeSnackbar"
    />
  </div>
</template>

<script>
import types from '../../store/types';

const viewContentMutations = types.mutations.viewContent;

export default {
  name: 'Snackbar',
  data: () => ({
    localSnackbars: [],
    snackbarCounter: 0
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === viewContentMutations.SHOW_SNACKBAR
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_SUCCESS
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_INFO
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_ERROR
        || mutation.type === viewContentMutations.SHOW_SNACKBAR_WARNING
      ) {
        this.addSnackbar(
          state.viewContent.snackbar.text,
          state.viewContent.snackbar.type,
          state.viewContent.snackbar.timeout
        );
      }
    });
  },
  methods: {
    addSnackbar(text, type, timeout) {
      this.localSnackbars.push({
        id: this.snackbarCounter++,
        text,
        type,
        timeout,
        trigger: true
      });
    },

    removeSnackbar(id) {
      this.localSnackbars = this.localSnackbars.filter((snackbar) => snackbar.id !== id);
    }
  }
};
</script>

<style>
#snackbar-wrapper {
  height: 0
}
</style>
