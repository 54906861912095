// create a welcome log
import { version } from '../../package.json';

const nodeEnv = import.meta.env.MODE;

const welcomeLog = () => {
  // create a bbh console logo
  const bbhLogo = ' __       __       __         \r\n/\\ \\     /\\ \\     /\\ \\        \r\n\\ \\ \\____\\ \\ \\____\\ \\ \\___    \r\n \\ \\ \'__`\\\\ \\ \'__`\\\\ \\  _ `\\  \r\n  \\ \\ \\L\\ \\\\ \\ \\L\\ \\\\ \\ \\ \\ \\ \r\n   \\ \\_,__/ \\ \\_,__/ \\ \\_\\ \\_\\\r\n    \\/___/   \\/___/   \\/_/\\/_/';
  console.log(
    '\n%cWelcome %cto %clemon\n'
  + `%c${bbhLogo}\n `
  + `\n%cyour client uses the version: %c${version}\n `
  + `${nodeEnv === 'production' ? '' : `\n%cYou are in the %c${nodeEnv}%c environment\n `}`,
    'font-weight: bold; font-size: 2rem',
    'font-size: 1.5rem',
    'font-weight: bold; color: #D2B900; font-size: 2rem',
    'color: #DC0C23; font-weight: bold; font-size: 1rem',
    '',
    'color: #007D55; font-weight: bold; font-size: 1rem',
    '',
    `${nodeEnv === 'production' ? '' : 'color: #D2B900; font-weight: bold; font-size: 1rem'}`,
    ''
  );
};

export default welcomeLog;
