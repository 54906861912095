import { component as MODULE } from '../store/state';

const MUTATIONS = {
  CLEAR_DATA: `${MODULE}clear_data`,
  CLEAR_HASHTAGS: `${MODULE}clear_hashtags`,
  CLEAR_HASHTAG_TYPES: `${MODULE}clear_hashtag_types`,
  CLEAR_CHOICES: `${MODULE}clear_choices`,
  CLEAR_QUESTIONS: `${MODULE}clear_questions`,
  CLEAR_ROLLES: `${MODULE}clear_rolles`,
  CLEAR_ELEMENTS: `${MODULE}clear_elements`,

  SET_HASHTAGS: `${MODULE}set_hashtags`,
  SET_HASHTAG_TYPES: `${MODULE}set_hashtag_types`,
  SET_CHOICES: `${MODULE}set_choices`,
  SET_QUESTIONS: `${MODULE}set_questions`,
  SET_ROLLES: `${MODULE}set_rolles`,
  SET_ELEMENTS: `${MODULE}set_elements`,
  SET_MUSTERVERTRAEGE: `${MODULE}set_mustervertraege`,
  SET_MUSTERVERTRAG_STATE_TO_APPROVED: `${MODULE}set_mustervertrag_state_to_approved`,

  INCREASE_RESET_FRAGEBOGEN_COUNTER: `${MODULE}increase_reset_fragebogen_counter`,

  SET_HILFE_VIDEO_ID: `${MODULE}set_hilfe_video_id`,
  SET_HILFE_VIDEO_NAME: `${MODULE}set_hilfe_video_name`,

  UPDATE_HASHTAG: `${MODULE}update_hashtag`,
  UPDATE_HASHTAG_TYPE: `${MODULE}update_hashtag_type`,
  UPDATE_CHOICE: `${MODULE}update_choice`,
  UPDATE_QUESTION: `${MODULE}update_question`,
  UPDATE_ROLLE: `${MODULE}update_rolle`,
  UPDATE_ELEMENT: `${MODULE}update_element`,
  UPDATE_MUSTERVERTRAG: `${MODULE}update_mustervertrag`,
  REPLACE_SUB_ELEMENT_WITH_DESCENDANT: `${MODULE}replace_sub_element_with_descendant`,

  INCREASE_REVISION_OF_MUSTERVERTRAG: `${MODULE}increase_revision_of_mustervertrag`,

  DEPRECATE_HASHTAG: `${MODULE}deprecate_hashtag`,
  DEPRECATE_HASHTAG_TYPE: `${MODULE}deprecate_hashtag_type`,
  DEPRECATE_CHOICE: `${MODULE}deprecate_choice`,
  DEPRECATE_QUESTION: `${MODULE}deprecate_question`,
  DEPRECATE_ROLLE: `${MODULE}deprecate_rolle`,
  DEPRECATE_ELEMENT: `${MODULE}deprecate_element`,

  ADD_HASHTAG: `${MODULE}add_hashtag`,
  ADD_CHOICE: `${MODULE}add_choice`,
  ADD_MUSTERVERTRAG: `${MODULE}add_mustervertrag`,
  ADD_SUBELEMENT_TO_MUSTERVERTRAG: `${MODULE}add_subelement_to_mustervertrag`,

  REMOVE_SUBELEMENT_IN_MUSTERVERTRAG:
    `${MODULE}remove_subelement_in_mustervertrag`,
  REMOVE_MUSTERVERTRAG: `${MODULE}remove_mustervertrag`,

  SHOW_SNACKBAR: `${MODULE}show_snackbar`,
  SHOW_SNACKBAR_ERROR: `${MODULE}show_snackbar_error`,
  SHOW_SNACKBAR_SUCCESS: `${MODULE}show_snackbar_success`,
  SHOW_SNACKBAR_WARNING: `${MODULE}show_snackbar_warning`,
  SHOW_SNACKBAR_INFO: `${MODULE}show_snackbar_info`
};

export default MUTATIONS;
