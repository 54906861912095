export default {
  clientElement: {},
  clientElements: [],
  currentClientElementId: '',

  mandantCompareElement: {},

  clientElementZwangsupdate: {},
  updateIsFinishedCounter: 0,
  revisionsArray: [],

  aenderungsmodus: false,
  aenderungsmodusHistory: {
    order: [],
    sections: {}
  },
  aenderungsmodusHistoryUpdateCounter: 0,
  aenderungsmodusOriginClientElement: {},
  aenderungsmodusSelectedBase: {},
  aenderungsmodusSelectedHead: {}
};

export const component = 'mandantEditor/';
