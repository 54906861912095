import { component as MODULE } from '../store/state';

const MUTATIONS = {
  SET_SUB_HEADER_LOADING: `${MODULE}set_sub_header_loading`,

  SUB_HEADER_ACTION_BAR: `${MODULE}sub_header_action_bar`,
  RESET_SUB_HEADER_ACTION_BAR: `${MODULE}reset_sub_header_action_bar`,
  SET_SUB_HEADER_ACTION_BAR_COMPONENT: `${MODULE}set_sub_header_action_bar_component`,

  NEUES_DOKUMENT: `${MODULE}neues_dokument`,
  NEUES_HASHTAG: `${MODULE}neues_hashtag`,
  NEUE_ENTSCHEIDUNG: `${MODULE}neue_entscheidung`,

  VERTRAG_BEARBEITEN: `${MODULE}vertrag_bearbeiten`,
  KOLLEKTIONEN: `${MODULE}kollektionen`,
  SET_KOLLEKTIONEN_BEARBEITEN: `${MODULE}set_kollektionen_bearbeiten`,

  APPROVE_CONTRACT: `${MODULE}approve_contract`,

  NEUE_AUFGABE: `${MODULE}neue_aufgabe`,

  SET_NEW_MUSTERELEMENT: `${MODULE}set_new_musterelement`,
  SET_MIGRATION: `${MODULE}set_migration`,
  SET_INDIVIDUELLER_VERTRAG: `${MODULE}set_individueller_vertrag`,

  EDIT_CONTRACT: `${MODULE}edit_contract`,

  SET_FRAGEBOGEN_BEARBEITEN: `${MODULE}set_fragebogen_bearbeiten`,
  SET_FRAGEBOGEN: `${MODULE}set_fragebogen`,

  SET_SERVICEZUGANG_DIALOG: `${MODULE}set_servicezugang_dialog`,

  PAKETVERWALTUNG: `${MODULE}paketverwaltung`
};

export default MUTATIONS;
