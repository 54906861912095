import { component as MODULE } from '../store/state';

const MUTATIONS = {
  SET_COMMENT: `${MODULE}set_comment`,
  SET_REFERENCE: `${MODULE}set_reference`,

  SET_FOCUSED_SECTION: `${MODULE}set_focused_section`,
  UPDATE_FOCUSED_SECTION_ATTRS: `${MODULE}update_focused_section_attrs`,
  SET_SECTION_RERENDER_MAP: `${MODULE}set_section_rerender_map`,

  SET_CHECKLIST: `${MODULE}set_checklist`,

  SET_FREIGABE_STATUS: `${MODULE}set_freigabe_status`,
  SET_FREIGABE_IS_APPROVAL: `${MODULE}set_freigabe_is_approval`,
  SET_FREIGABE_TASKID: `${MODULE}set_freigabe_taskid`,

  RESET_FREIGABEPROZESS: `${MODULE}reset_freigabeprozess`,

  UPDATE_REFERENCE: `${MODULE}update_reference`,
  UPDATE_SECTION_RERENDER_MAP: `${MODULE}update_section_rerender_map`,

  CLEAR_COMMENT: `${MODULE}clear_comment`,
  CLEAR_REFERENCE: `${MODULE}clear_reference`,

  TOGGLE_CHECKLIST_CHECKED: `${MODULE}toggle_checklist_checked`,
  TOGGLE_CHECKLIST_SECTION_CHECKED: `${MODULE}toggle_checklist_section_checked`,
  UPDATE_CHECKLIST_CHECKED_EXCEPT: `${MODULE}update_checklist_checked_except`,

  INCREASE_UPDATE_VALUE_COUNTER: `${MODULE}increase_update_value_counter`,
  INCREASE_RESET_TO_REVISION_COUNTER: `${MODULE}increase_reset_to_revision_counter`,
  INCREASE_RELOAD_ELEMENT_COUNTER: `${MODULE}increase_reload_element_counter`,

  INCREASE_ELEMENT_TAB_TRIGGER: `${MODULE}increase_element_tab_trigger`,

  UPDATE_COMPARE_MODE: `${MODULE}update_compare_mode`,

  SET_LAST_SAVED_REQUEST_PAYLOAD: `${MODULE}sset_last_saved_request_payload`,

  SET_GROUP_SECTIONS_MODE: `${MODULE}set_group_sections_mode`
};

export default MUTATIONS;
