export default class UpdateType {
  static FRAGEBOGEN = 'fragebogen';

  static INHALT = 'inhalt';

  static isValidType(type) {
    const states = [
      this.INHALT, this.FRAGEBOGEN
    ];
    return states.includes(type);
  }

  static isFragebogen(type) {
    return type === this.FRAGEBOGEN;
  }

  static isInhalt(type) {
    return type === this.INHALT;
  }
}
