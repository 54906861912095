import { get } from '../axios';
import config from './config';

const { API_PREFIX } = config;

export default {
  /**
   * get users servicezugaege
   *
   * @param {String} userId
   * @returns {Promise}
   */
  getServicezugaenge(userId) {
    const URL = `${API_PREFIX}/servicezugaenge/${userId}`;
    return get(URL);
  }
};
