import { isJSON } from '@st/utils-js';
import FrageTyp from './enums/FrageTyp';
import { uuid, validate } from '../utils/uuid';
import Antwort from './Antwort';

export default class Frage {
  /**
   *
   * @param {String} id UUID
   * @param {String} type value of FrageTyp
   * @param {String} text
   * @param {Array} content if the type "question" it is an Array of Antwort
   *  and if the type ist "questionPlatzhalter" it contains UUIDs of placeholder
   * @param {String} internalComment optional
   * @param {String} externalComment optional
   * @param {String} conditionAnswerId optional
   */
  constructor(
    id = uuid(),
    type = FrageTyp.QUESTION,
    text = '',
    content = [],
    internalComment = '',
    externalComment = '',
    conditionAnswerId = undefined
  ) {
    this.type = type;
    this.text = text;
    this.attrs = {
      id,
      internalComment,
      externalComment,
      conditionAnswerId
    };
    if (FrageTyp.isQuestion(type)) {
      this.content = content;
    } else if (FrageTyp.isQuestionPlatzhalter(type)) {
      this.placeholderIds = content;
    }
  }

  clone(conditionMapping = {}) {
    let content = [];
    if (FrageTyp.isQuestion(this.type)) {
      content = this.content.map((antwort) => antwort.clone());
    } else if (FrageTyp.isQuestionPlatzhalter(this.type)) {
      content = this.placeholderIds;
    }

    const conditionAnswerId = conditionMapping[this.attrs.conditionAnswerId]
      || this.attrs.conditionAnswerId;

    return new Frage(
      undefined,
      this.type,
      this.text,
      content,
      this.attrs.internalComment,
      this.attrs.externalComment,
      conditionAnswerId
    );
  }

  /**
   * Creates Frage from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      type = FrageTyp.QUESTION,
      text = '',
      attrs = {
        id: null,
        internalComment: '',
        externalComment: '',
        conditionAnswerId: undefined
      }
    } = value;

    let contentKey = '';
    if (FrageTyp.isQuestion(type)) {
      contentKey = 'content';
    } else if (FrageTyp.isQuestionPlatzhalter(type)) {
      contentKey = 'placeholderIds';
    }

    let content = [];
    if (Array.isArray(value[contentKey])) {
      content = value[contentKey];
    }

    if (FrageTyp.isQuestion(type)) {
      content = content
        .map((answer) => Antwort.fromJSON(answer));
    } else if (FrageTyp.isQuestionPlatzhalter(type)) {
      content = content.filter((placeholderId) => validate(placeholderId));
    }

    content = content.filter((v) => !!v);

    return new Frage(
      attrs.id,
      type,
      text,
      content,
      attrs.internalComment,
      attrs.externalComment,
      attrs.conditionAnswerId
    );
  }

  /**
   * @returns Frage in JSON representation
   */
  toJSON() {
    const returnObj = {
      text: this.text,
      type: this.type,
      attrs: this.attrs
    };

    if (FrageTyp.isQuestion(this.type)) {
      returnObj.content = this.content.map((answer) => ({ ...answer }));
    } else if (FrageTyp.isQuestionPlatzhalter(this.type)) {
      returnObj.placeholderIds = this.placeholderIds;
    }

    return returnObj;
  }

  get validType() {
    return !!this.type
    && typeof this.type === 'string'
    && FrageTyp.isValidType(this.type);
  }

  get validText() {
    return typeof this.text === 'string' && !!this.text.trim();
  }

  get validInternalComment() {
    return typeof this.attrs.internalComment === 'string';
  }

  get validExternalComment() {
    return typeof this.attrs.externalComment === 'string';
  }

  get validQuestion() {
    return Array.isArray(this.content)
        && this.content.length !== 0
        && this.content.every((answer) => answer.valid);
  }

  get validQuestionPlaceholder() {
    return Array.isArray(this.placeholderIds)
        && this.placeholderIds.length !== 0
        && this.placeholderIds.every((placeholderId) => validate(placeholderId));
  }

  get validContent() {
    let validContent = false;
    if (FrageTyp.isQuestion(this.type)) {
      validContent = this.validQuestion;
    } else if (FrageTyp.isQuestionPlatzhalter(this.type)) {
      validContent = this.validQuestionPlaceholder;
    }
    return validContent;
  }

  get validConditionalAnswerId() {
    return this.attrs.conditionAnswerId === undefined
      || validate(this.attrs.conditionAnswerId);
  }

  get validId() {
    return validate(this.attrs.id);
  }

  get valid() {
    const valid = this.validId
      && this.validType
      && this.validText
      && this.validInternalComment
      && this.validExternalComment
      && this.validContent
      && this.validConditionalAnswerId;
    return valid;
  }
}
