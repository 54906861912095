import {
  get, post, put, remove
} from '../axios';

const API_PREFIX = '/aufgaben';

export default {
  getAufgabenElements() {
    const URL = `${API_PREFIX}/aufgabe/element`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  /**
   * get all my "aufgaben"
   * @returns
   */
  getAufgabenTypen() {
    const URL = `${API_PREFIX}/aufgabe/type`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  /**
   * get all my "aufgaben"
   * @returns
   */
  getAufgaben() {
    const URL = `${API_PREFIX}/aufgabe`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  /**
   * Post new "aufgabe"
   * @returns
   */
  postAufgabe(body) {
    const URL = `${API_PREFIX}/aufgabe`;

    return new Promise((resolve, reject) => {
      post(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  /**
   * Put new "aufgabeStatus"
   * @param {String} aufgabeId
   * @param {String} status
   * @returns
   */
  putAufgabeStatus(aufgabeId, status) {
    const URL = `${API_PREFIX}/aufgabe/${aufgabeId}/status/${status}`;

    return new Promise((resolve, reject) => {
      put(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  postAufgabeDelegieren(aufgabeId, body) {
    const URL = `${API_PREFIX}/aufgabe/${aufgabeId}/delegieren`;

    return new Promise((resolve, reject) => {
      post(URL, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  removeAufgabeDelegieren(aufgabeId) {
    const URL = `${API_PREFIX}/aufgabe/${aufgabeId}/delegieren`;

    return new Promise((resolve, reject) => {
      remove(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.showSnackbarError('Sie haben keine Berechtigung für diese Seite');
          }
          reject(error);
        });
    });
  },
  getAufgabenDelegierenRoles() {
    const URL = `${API_PREFIX}/aufgabe/delegieren/roles`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getAufgabenDelegierenUser() {
    const URL = `${API_PREFIX}/aufgabe/delegieren/user`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  postAufgabeUebernehmen(aufgabeId) {
    const URL = `${API_PREFIX}/aufgabe/${aufgabeId}/uebernehmen`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  /**
   * check if the is a "aufgabe" for the current user
   * for a given elementId
   * @returns
   */
  getElementGetFreigabeAufgabe(elementId) {
    const URL = `${API_PREFIX}/aufgabe/element/${elementId}/freigabeAufgabe`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  /**
   * check if the is a "aufgabe" for the current user
   * for a given elementId
   * @returns
   */
  getFreigabeNeuerMandant(aufgabeBearbeiterId) {
    const URL = `${API_PREFIX}/freigabeNeuerMandant/${aufgabeBearbeiterId}`;

    return new Promise((resolve, reject) => {
      get(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  /**
   * check if the is a "aufgabe" for the current user
   * for a given elementId
   * @returns
   */
  postFreigabeNeuerMandant(aufgabeBearbeiterId) {
    const URL = `${API_PREFIX}/freigabeNeuerMandant/${aufgabeBearbeiterId}`;

    return new Promise((resolve, reject) => {
      post(URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  postUeberarbeitenNeuerMandant(aufgabeBearbeiterId, data) {
    const URL = `${API_PREFIX}/ueberarbeitenNeuerMandant/${aufgabeBearbeiterId}`;

    return new Promise((resolve, reject) => {
      post(URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
};
