<template>
  <div
    class="freigabe-checkbox-wrapper"
    :style="flexStyle"
  >
    <div
      class="freigabe-checkbox"
    >
      <template v-if="tooltip === ''">
        <bbh-checkbox
          :value="checkboxValue"
          :label="label"
          class="mb-2"
          :dense="dense"
          :padding-dense="paddingDense"
          @update:value="handleValueUpdate($event)"
        />
      </template>
      <template v-else>
        <bbh-tooltip>
          <template #activator>
            <bbh-checkbox
              :value="checkboxValue"
              :label="label"
              :dense="dense"
              :padding-dense="paddingDense"
              class="mb-2"
              @update:value="handleValueUpdate($event)"
            />
          </template>
          {{ tooltip }}
        </bbh-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import types from '../../store/types';

const sharedEditorGetters = types.getters.sharedEditor;
const sharedEditorActions = types.actions.sharedEditor;

export default {
  name: 'FreigabeCheckbox',

  props: {
    value: { type: Boolean, default: false },
    keys: { type: Array, default: () => ([]) },
    revision: {
      type: [
        Number, Object
      ],
      default: null
    },
    elementId: {
      type: [
        String, Object
      ],
      default: undefined
    },
    label: { type: String, default: '' },
    tooltip: { type: String, default: 'Für den Freigabeprozess überprüft' },
    dense: { type: Boolean, default: false },
    justifyContent: { type: String, default: 'center' },
    customStyle: { type: Object, default: () => ({}) },
    paddingDense: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters({
      checked: sharedEditorGetters.FREIGABEPROZESS_CHECKLIST_CHECKED
    }),

    ...mapState({
      currentDocId: (state) => state.editor.currentDocId,
      element: (state) => state.editor.element
    }),

    checkboxValue() {
      if (!this.checkKey) return false;
      return this.checked({ key: this.checkKey, revision: this.revision });
    },
    checkKey() {
      return this.keys.join('_');
    },
    flexStyle() {
      return {
        justifyContent: this.justifyContent
      };
    }
  },

  methods: {
    ...mapActions({
      toggleChecked: sharedEditorActions.TASKPROGRESS_CHECK
    }),
    handleValueUpdate(value) {
      const {
        editorElement: editorElementId,
        elementRevisions
      } = this.element;

      const revision = this.revision ? this.revision : elementRevisions[editorElementId];
      this.toggleChecked({ key: this.checkKey, revision, elementId: this.elementId });
      this.emitUpdate(value);
    },
    emitUpdate(value) {
      this.$emit('update:value', value);
    }
  }
};
</script>

<style lang="scss">
.freigabe-checkbox-wrapper {
  display: flex;
  flex-shrink: 1;

  width: 100%;

  & .freigabe-checkbox {
    display: inline-flex;
  }

  & .v-input--selection-controls__input {
    background-color: white;
    border-radius: 14px;
  }
}
</style>
