import { component as MODULE } from '../store/state';

const ACTIONS = {
  GET_FREIGABE_STATUS: `${MODULE}get_freigabe_status`,
  GET_INTERNE_FREIGABE_STATUS: `${MODULE}get_interne_freigabe_status`,
  GET_MANDANT_FREIGABE_STATUS: `${MODULE}get_mandant_freigabe_status`,

  TASKPROGRESS_CHECK: `${MODULE}taskprogress_check`,
  TASKPROGRESS_CHECK_SECTION: `${MODULE}taskprogress_check_section`,
  FETCH_TASKPROGRESS: `${MODULE}fetch_taskprogress`,
  UPDATE_CHECKBOX_TASKPROGRESS: `${MODULE}update_checkbox_taskprogress`,
  UPDATE_TASKPROGRESS: `${MODULE}update_taskprogress`,
  TOGGLE_CHECKBOX_TASKPROGRESS: `${MODULE}toggle_checkbox_taskprogress`
};

export default ACTIONS;
