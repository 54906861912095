import { component as MODULE } from '../store/state';

const ACTIONS = {
  GET_USER_DETAILS: `${MODULE}get_user_details`,

  FETCH_USER_CLIENTS: `${MODULE}fetch_user_clients`,

  PUT_CLIENT: `${MODULE}put_client`,
  PUT_USER_DETAILS: `${MODULE}put_user_details`,

  GET_ALL_USERS_OF_CLIENT: `${MODULE}get_all_users_of_client`,

  PUT_CLIENT_ELEMENT_RESPONSIBILITY: `${MODULE}put_client_element_responsibility`,
  DELETE_CLIENT_ELEMENT_RESPONSIBILITY: `${MODULE}delete_client_element_responsibility`,

  GET_USER_TAGS: `${MODULE}get_user_tags`,
  PUT_USER_TAG: `${MODULE}put_user_tag`,
  DELETE_USER_TAG: `${MODULE}delete_user_tag`,

  POST_REQUEST_ROLE: `${MODULE}post_request_role`,
  DELETE_KEYCLOAK_ROLE: `${MODULE}delete_keycloak_role`,
  DELETE_ADDITIONAL_ROLE: `${MODULE}delete_additional_role`,

  GET_PATH_PREFERENCE: `${MODULE}get_path_preference`
};

export default ACTIONS;
